import { DefaultTheme } from '@react-navigation/native';

import { setOpacity } from './Style';


const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};

export const COLORS = {
  TRANSPARENT: setOpacity('#FFFFFF', 0),
  GREY: 'grey',
  BLACK: 'black',
  SHARK: '#181920',
  CORAL: '#FF7F50',
  CORAL_RED: '#FF3648',
  SUNSET_ORANGE: '#FF5252',
  SUNSET_ORANGE_2: '#FF5050',
  WHITE: '#FFFFFF',
  WHITE_80: setOpacity('#FFFFFF', 80),
  WHITE_90: setOpacity('#FFFFFF', 90),
  ALTO: '#D8D8D8',
  MOUNTAIN_MIST: '#9D9CA0',
  COCOA_BROWN: '#201818',
  GALLERY: '#EFEEEE',
  MANATEE: '#9C9DA0',
  BURNT_SIENNA: '#EC5D57',
  PIPPIN: '#FFE9E1',
  DODGER_BLUE: '#2C88FF',
  CERULEAN: '#03A9F4',
  RED_ORANGE: '#FF3131',
  GREEN_SUCCESS: '#2ED573',
  BLACK_0: setOpacity('#000000', 0),
  BLACK_50: setOpacity('#000000', 50),
  BLACK_60: setOpacity('#000000', 60),
  BLACK_80: setOpacity('#000000', 80),
};

export const THEME = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: COLORS.WHITE,
  },
};
