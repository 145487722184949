import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import { DrawerActions } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import { defaultSystemFonts, RenderHTML } from 'react-native-render-html';
import { mvs } from 'react-native-size-matters';
import { WebView } from 'react-native-webview';

import { useAppSelector } from '../app/hooks';
import { MenuIcon } from '../components/MenuIcon';
import { ScreenLayout } from '../components/ScreenLayout';
import { TabName } from '../components/TabName';
import { COLORS, isTablet, REDUCED_HORIZONTAL_MARGIN, WINDOW_WIDTH } from '../constants';
import {
  selectDivRadius,
  selectFontFamily,
  selectHasMargin,
} from '../features/appconfig/store/selectors';
import { generateFontVariantsForRenderingRawHTML } from '../helpers/fontMapper';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import { HtmlTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';

export default function HtmlScreen({
  route,
  navigation,
}): LeftDrawerNavigatorScreenProps<'Rss'> {
  const tabOptions = route.params.options as HtmlTabModel;
  const [loaderVisible, setLoaderVisible] = useState(true);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);

  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const containerHorizontalMargin = hasMargin ? mvs(30) : REDUCED_HORIZONTAL_MARGIN;

  const renderers = {
    iframe: IframeRenderer,
  };

  const selectedFontVariants = generateFontVariantsForRenderingRawHTML(fontFamily);

  const systemFonts = [...defaultSystemFonts, ...selectedFontVariants];

  const customHTMLElementModels = {
    iframe: iframeModel,
  };
  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  // Adjusted font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));
  const paragraphFontSize = useAdjustedFontSize(15);

  return (
    <ScreenLayout
      headerContents={
        <>
          <MenuIcon
            color={tabOptions.headerTextColor}
            toggleLeftDrawer={toggleLeftDrawer}
          />
          <TabName
            title={tabOptions.titre}
            color={tabOptions.headerTextColor}
            titleFontSize={titleFontSize}
            titleLineHeight={titleLineHeight}
          />
        </>
      }
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        overScrollMode={'never'}
      >
        <View
          style={[
            styles.container,
            {
              backgroundColor: tabOptions.backgroundColor,
              borderRadius: mvs(divRadius),
              marginHorizontal: isTablet ? mvs(30) : containerHorizontalMargin,
            },
          ]}
        >
          {loaderVisible && <ActivityIndicator size="large" />}
          <RenderHTML
            WebView={WebView}
            renderers={renderers}
            customHTMLElementModels={customHTMLElementModels}
            contentWidth={WINDOW_WIDTH - 120} // 120 pour la taille des margins/paddings
            source={{ html: tabOptions.codeHTML }}
            tagsStyles={{
              body: {
                fontFamily: selectedFontVariants[0],
              },
              strong: {
                fontFamily: selectedFontVariants[1],
              },
              span: { width: 'auto' },
              p: { width: 'auto', fontSize: paragraphFontSize },
            }}
            systemFonts={systemFonts}
            onHTMLLoaded={() => {
              setLoaderVisible(false);
            }}
          />
        </View>
      </ScrollView>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: mvs(31),
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,
  },
});
