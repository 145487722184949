import { AppRegistry } from 'react-native';

import appConfig from './app.json';
import { AppWrapper } from './App.web';
import comfortaaBold from './assets/fonts/Comfortaa-Bold.ttf';
import comfortaaRegular from './assets/fonts/Comfortaa-Regular.ttf';
import comfortaaSemiBold from './assets/fonts/Comfortaa-SemiBold.ttf';
import dancingScriptBold from './assets/fonts/DancingScript-Bold.ttf';
import dancingScriptRegular from './assets/fonts/DancingScript-Regular.ttf';
import dancingScriptSemiBold from './assets/fonts/DancingScript-SemiBold.ttf';
import ebGaramondBold from './assets/fonts/EBGaramond-Bold.ttf';
import ebGaramondRegular from './assets/fonts/EBGaramond-Regular.ttf';
import ebGaramondSemiBold from './assets/fonts/EBGaramond-SemiBold.ttf';
import fredokaBold from './assets/fonts/Fredoka-Bold.ttf';
import fredokaRegular from './assets/fonts/Fredoka-Regular.ttf';
import fredokaSemiBold from './assets/fonts/Fredoka-SemiBold.ttf';
import josefinSansBold from './assets/fonts/JosefinSans-Bold.ttf';
import josefinSansRegular from './assets/fonts/JosefinSans-Regular.ttf';
import josefinSansSemiBold from './assets/fonts/JosefinSans-SemiBold.ttf';
import oswaldBold from './assets/fonts/Oswald-Bold.ttf';
import oswaldRegular from './assets/fonts/Oswald-Regular.ttf';
import oswaldSemiBold from './assets/fonts/Oswald-SemiBold.ttf';
import playfairDisplayBold from './assets/fonts/PlayfairDisplay-Bold.ttf';
import playfairDisplayRegular from './assets/fonts/PlayfairDisplay-Regular.ttf';
import playfairDisplaySemiBold from './assets/fonts/PlayfairDisplay-SemiBold.ttf';
import poppinsBold from './assets/fonts/Poppins-Bold.ttf';
import poppinsRegular from './assets/fonts/Poppins-Regular.ttf';
import poppinsSemiBold from './assets/fonts/Poppins-SemiBold.ttf';
import quickSandBold from './assets/fonts/Quicksand-Bold.ttf';
import quickSandRegular from './assets/fonts/Quicksand-Regular.ttf';
import quickSandSemiBold from './assets/fonts/Quicksand-SemiBold.ttf';
import ralewayBold from './assets/fonts/Raleway-Bold.ttf';
import ralewayRegular from './assets/fonts/Raleway-Regular.ttf';
import ralewaySemiBold from './assets/fonts/Raleway-SemiBold.ttf';
import robotoBold from './assets/fonts/Roboto-Bold.ttf';
import robotoRegular from './assets/fonts/Roboto-Regular.ttf';
import robotoSemiBold from './assets/fonts/Roboto-SemiBold.ttf';
import robotoMonoBold from './assets/fonts/RobotoMono-Bold.ttf';
import robotoMonoRegular from './assets/fonts/RobotoMono-Regular.ttf';
import robotoMonoSemiBold from './assets/fonts/RobotoMono-SemiBold.ttf';
import solwayBold from './assets/fonts/Solway-Bold.ttf';
import solwayRegular from './assets/fonts/Solway-Regular.ttf';
import solwaySemiBold from './assets/fonts/Solway-SemiBold.ttf';

const regularFontStyles = `
  @font-face {
    src: url(${quickSandRegular});
    font-family: 'Quicksand-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${poppinsRegular});
    font-family: 'Poppins-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${dancingScriptRegular});
    font-family: 'DancingScript-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${comfortaaRegular});
    font-family: 'Comfortaa-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${ebGaramondRegular});
    font-family: 'EBGaramond-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${fredokaRegular});
    font-family: 'Fredoka-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${josefinSansRegular});
    font-family: 'JosefinSans-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${oswaldRegular});
    font-family: 'Oswald-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${playfairDisplayRegular});
    font-family: 'PlayfairDisplay-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${ralewayRegular});
    font-family: 'Raleway-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${robotoRegular});
    font-family: 'Roboto-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${robotoMonoRegular});
    font-family: 'RobotoMono-Regular';
    font-display: block;
  }
  @font-face {
    src: url(${solwayRegular});
    font-family: 'Solway-Regular';
    font-display: block;
  }
`;

const semiFontStyles = `
  @font-face {
    src: url(${quickSandSemiBold});
    font-family: 'Quicksand-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${poppinsSemiBold});
    font-family: 'Poppins-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${dancingScriptSemiBold});
    font-family: 'DancingScript-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${comfortaaSemiBold});
    font-family: 'Comfortaa-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${ebGaramondSemiBold});
    font-family: 'EBGaramond-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${fredokaSemiBold});
    font-family: 'Fredoka-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${josefinSansSemiBold});
    font-family: 'JosefinSans-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${oswaldSemiBold});
    font-family: 'Oswald-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${playfairDisplaySemiBold});
    font-family: 'PlayfairDisplay-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${ralewaySemiBold});
    font-family: 'Raleway-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${robotoSemiBold});
    font-family: 'Roboto-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${robotoMonoSemiBold});
    font-family: 'RobotoMono-SemiBold';
    font-display: block;
  }
  @font-face {
    src: url(${solwaySemiBold});
    font-family: 'Solway-SemiBold';
    font-display: block;
  }
`;

const boldFontStyles = `
  @font-face {
    src: url(${quickSandBold});
    font-family: 'Quicksand-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${poppinsBold});
    font-family: 'Poppins-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${dancingScriptBold});
    font-family: 'DancingScript-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${comfortaaBold});
    font-family: 'Comfortaa-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${ebGaramondBold});
    font-family: 'EBGaramond-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${fredokaBold});
    font-family: 'Fredoka-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${josefinSansBold});
    font-family: 'JosefinSans-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${oswaldBold});
    font-family: 'Oswald-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${playfairDisplayBold});
    font-family: 'PlayfairDisplay-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${ralewayBold});
    font-family: 'Raleway-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${robotoBold});
    font-family: 'Roboto-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${robotoMonoBold});
    font-family: 'RobotoMono-Bold';
    font-display: block;
  }
  @font-face {
    src: url(${solwayBold});
    font-family: 'Solway-Bold';
    font-display: block;
  }
`;

const style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = regularFontStyles;
} else {
  style.appendChild(document.createTextNode(regularFontStyles));
  style.appendChild(document.createTextNode(semiFontStyles));
  style.appendChild(document.createTextNode(boldFontStyles));
  style.appendChild(document.createTextNode('svg:focus{outline:none}'));
}

// Inject stylesheet
document.head.appendChild(style);

const appName = appConfig.name;
AppRegistry.registerComponent(appName, () => AppWrapper);
AppRegistry.runApplication(appName, { rootTag: document.getElementById('root') });
