import { makeApiCall } from './api';
import { RssContentModel } from '../models';

export const getRssContent = async (
  urlBase64: string,
  currentPage: number,
  bearerToken: string,
  appId: number,
): Promise<Readonly<RssContentModel>> => {
  const config = {
    Authorization: 'Bearer ' + bearerToken,
  };
  const res = await makeApiCall<RssContentModel>({
    url: `${process.env.REACT_APP_MOBILE_API_URL}/${appId}/read-rss/${urlBase64}?page=${currentPage}`,
    headers: config,
  });
  return res.data;
};
