// See https://github.com/date-fns/date-fns/issues?q=%22no-duplicate%22
import { format, formatDistanceToNow, parseJSON } from 'date-fns';
import { de, enUS as en, es, fr, it, pt } from 'date-fns/locale';

import i18n from '../i18n';
import { SupportedLocalesCodes } from '../supportedLocales';

export type DateTimeLocales = { [T in SupportedLocalesCodes]: typeof en };

const locales: DateTimeLocales = {
  en,
  fr,
  es,
  de,
  pt,
  it,
};

export function formatFromNow(...args: Parameters<typeof formatDistanceToNow>) {
  const locale =
    locales[i18n.locale as SupportedLocalesCodes] ??
    locales[i18n.defaultLocale as SupportedLocalesCodes];

  return formatDistanceToNow(args[0], {
    locale,
    ...args[1],
  });
}

/**
 * Parse date from SQL UTC format
 *
 * Example: 2022-01-19 10:54:00
 */
export function dateFromSQLUTC(SQLUTCDate: string) {
  return parseJSON(SQLUTCDate);
}

export function formatForAlarm(date: Date) {
  const locale =
    locales[i18n.locale as SupportedLocalesCodes] ??
    locales[i18n.defaultLocale as SupportedLocalesCodes];

  return format(date, 'PPPP', { locale: locale });
}
