import { makeApiCall } from './api';
import {
  DedicationModel,
  LiveFeedPostModel,
  LiveFeedPostSurveyAnswer,
  LiveFeedPostSurveyModel,
} from '../models';

export const getLiveFeedPosts = async (
  radioWebsiteUrl: string,
  bearerToken: string, //to keep the same signature as web
): Promise<LiveFeedPostModel[]> => {
  const config = {
    Authorization: 'Bearer ' + bearerToken,
  };
  const res = await makeApiCall<LiveFeedPostModel[]>({
    url: `${process.env.REACT_APP_MOBILE_API_URL}/radio-website?url=${radioWebsiteUrl}&module=livefeed`,
    headers: config,
  });
  return res.data;
};

export const getDedications = async (
  radioWebsiteUrl: string,
  bearerToken: string, //to keep the same signature as web
): Promise<DedicationModel[]> => {
  const config = {
    Authorization: 'Bearer ' + bearerToken,
  };
  const res = await makeApiCall<DedicationModel[]>({
    url: `${process.env.REACT_APP_MOBILE_API_URL}/radio-website?url=${radioWebsiteUrl}&module=dedicaces`,
    headers: config,
  });
  return res.data;
};

export const sendVoteLiveFeedSurvey = async (
  _radioWebsiteUrl: string,
  _liveFeedPostId: LiveFeedPostSurveyModel['idlivefeeds'],
  _answer: LiveFeedPostSurveyAnswer,
): Promise<void> => {
  return true;
};
