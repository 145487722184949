import { DrawerActions } from '@react-navigation/native';
import { Avatar } from '@rneui/themed';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { mvs } from 'react-native-size-matters';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { FloatingActionButton } from '../components/FloatingActionButton';
import { MenuIcon } from '../components/MenuIcon';
import { ScreenLayout } from '../components/ScreenLayout';
import { TabName } from '../components/TabName';
import {
  DataState,
  isTablet,
  REDUCED_HORIZONTAL_MARGIN,
  sampleColorFromString,
} from '../constants';
import {
  selectAppConfig,
  selectDivRadius,
  selectFontFamily,
  selectHasMargin,
  selectTextAlign,
} from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import { showAlert } from '../features/modal/store/actions';
import { fetchTextDedications } from '../features/textDedication/store/actions';
import {
  selectTextDedications,
  selectTextDedicationsState,
} from '../features/textDedication/store/selectors';
import { getFontFamily } from '../helpers/fontMapper';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import { useShadowStyles } from '../hooks/useShadowStyles';
import { dateFromSQLUTC, formatFromNow } from '../localization/datetime';
import i18n from '../localization/i18n';
import { DedicationModel, DedicationTabModel, TextAlign } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';

export default function TextDedicationScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'TextDedication'>) {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const dedications = useAppSelector(selectTextDedications);
  const dedicationsState = useAppSelector(selectTextDedicationsState);
  const dedicationsListRef = useRef<FlatList<DedicationModel>>(null);
  const [refreshing, setRefreshing] = useState(false);
  const tabOptions = route.params.options as DedicationTabModel;
  const bearerToken = useAppSelector(selectBearerToken);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  // Dynamic font size adjustments
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));
  const dedicationUserFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const dedicationDateFontSize = useAdjustedFontSize(mvs(14, 0.35));
  const dedicationMessageFontSize = useAdjustedFontSize(mvs(20, 0.35));
  const avatarSize = useAdjustedFontSize(mvs(51));
  const dedicationHeaderPaddingLeft = useAdjustedFontSize(mvs(55, 0.35));

  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const bodyPadding = hasMargin ? mvs(32) : REDUCED_HORIZONTAL_MARGIN;
  const avatarLeftoffset = hasMargin ? mvs(-10, 0.35) : 0;
  const bodyWidth = hasMargin ? 320 : 'auto';

  const fetchDedications = useCallback(() => {
    dispatch(fetchTextDedications(appConfig, bearerToken));
  }, [appConfig, bearerToken, dispatch]);

  useEffect(() => {
    fetchDedications();
  }, [fetchDedications]);

  useEffect(() => {
    if (dedicationsState !== DataState.LOADING) {
      setRefreshing(false);
    }

    if (dedicationsState === DataState.ERROR) {
      dispatch(
        showAlert({
          key: 'fetch-dedications-error',
          type: 'warning',
          text: i18n.t('alert.noConnection'),
          closable: false,
          autoCloseAfterMs: 2000,
        }),
      );
    }
  }, [dedicationsState, dispatch]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchDedications();
  }, [fetchDedications]);

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const renderDedication = useCallback(
    ({ item: dedication }: ListRenderItemInfo<DedicationModel>) => {
      const pseudo = dedication.pseudo?.trim() ?? '';
      const message = dedication.message?.trim() ?? '';
      const formattedDate = dedication.date_dedicace
        ? formatFromNow(dateFromSQLUTC(dedication.date_dedicace))
        : '';
      const avatarTitle = pseudo[0];
      const avatarColorStyle = { backgroundColor: sampleColorFromString(pseudo) };
      const dynamicMessageStyles = {
        paddingLeft: textAlign === 'center' ? mvs(35, 0.35) : dedicationHeaderPaddingLeft,
      };
      const dynamicHeaderStyles = (align: TextAlign): ViewStyle => {
        switch (align) {
          case 'center':
            return { justifyContent: 'center' };
          case 'right':
            return { justifyContent: 'flex-end' };
          default:
            return {};
        }
      };

      return (
        <View style={[styles.dedication, { borderRadius: mvs(divRadius, 0.35) }]}>
          <View
            style={[
              styles.dedicationHeader,
              { paddingLeft: dedicationHeaderPaddingLeft },
              dynamicHeaderStyles(textAlign),
            ]}
          >
            <CustomText
              numberOfLines={1}
              style={[styles.dedicationUser, { fontSize: dedicationUserFontSize }]}
              weight="SemiBold"
            >
              {pseudo}
            </CustomText>
            <CustomText
              numberOfLines={1}
              style={[styles.dedicationDate, { fontSize: dedicationDateFontSize }]}
              weight="Regular"
            >
              - {formattedDate}
            </CustomText>
          </View>
          <CustomText
            style={[
              styles.dedicationMessage,
              dynamicMessageStyles,
              { fontSize: dedicationMessageFontSize },
            ]}
            weight="Regular"
            textAlign={textAlign}
          >
            {message}
          </CustomText>
          <Avatar
            title={avatarTitle.toUpperCase()}
            rounded
            size={avatarSize}
            containerStyle={[
              styles.avatarContainer,
              avatarColorStyle,
              { left: isTablet ? mvs(-10, 0.35) : avatarLeftoffset },
              shadowStyles,
            ]}
            titleStyle={[
              styles.avatarText,
              { fontFamily: getFontFamily(fontFamily, 'Regular') },
            ]}
          />
        </View>
      );
    },
    [
      textAlign,
      dedicationHeaderPaddingLeft,
      divRadius,
      dedicationUserFontSize,
      dedicationDateFontSize,
      dedicationMessageFontSize,
      avatarSize,
      avatarLeftoffset,
      shadowStyles,
      fontFamily,
    ],
  );

  const dedicationsListKeyExtractor = useCallback(
    (item: DedicationModel) => `${item.id_dedicace}`,
    [],
  );

  return (
    <ScreenLayout
      headerContents={
        <>
          <MenuIcon
            color={tabOptions.headerTextColor}
            toggleLeftDrawer={toggleLeftDrawer}
          />
          <TabName
            title={tabOptions.titre}
            color={tabOptions.headerTextColor}
            titleFontSize={titleFontSize}
            titleLineHeight={titleLineHeight}
          />
        </>
      }
    >
      <View style={[styles.container]}>
        <FlatList
          ref={dedicationsListRef}
          data={dedications}
          renderItem={renderDedication}
          keyExtractor={dedicationsListKeyExtractor}
          contentContainerStyle={[
            styles.body,
            {
              paddingHorizontal: bodyPadding,
              width: isTablet ? mvs(450, 0.35) : bodyWidth,
            },
          ]}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
        <FloatingActionButton
          icon={'icon_edit_with_offset'}
          onPress={() => {}}
          iconSize={mvs(17)}
          hidden={!appConfig.urlSiteRadio}
          disabled
          text={i18n.t('textDedication.writeDedication')}
          containerStyle={isTablet && { right: undefined }}
        />
      </View>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'stretch',
  },
  body: {
    flex: 1,
    paddingTop: mvs(18),
    paddingBottom: isTablet ? mvs(69) : 0,
  },
  dedication: {
    backgroundColor: '#fff',
    paddingVertical: mvs(15, 0.35),
    marginBottom: mvs(26, 0.35),
  },
  dedicationHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: mvs(35, 0.35),
  },
  dedicationUser: {
    color: '#9C9C9C',
  },
  dedicationDate: {
    color: '#9C9C9C',
    marginLeft: mvs(3, 0.35),
  },
  dedicationMessage: {
    color: '#474747',
    paddingRight: mvs(35, 0.35),
  },
  avatarContainer: {
    backgroundColor: '#BDBDBD',
    borderWidth: mvs(3, 0.35),
    borderColor: '#fff',
    position: 'absolute',
    left: mvs(-10, 0.35),
    top: mvs(-12, 0.35),
  },
  avatarText: {
    color: '#fff',
  },
});
