import { ReactNode } from 'react';
import TextTicker, { TextTickerProps } from 'react-native-text-ticker';

import { RUN_AS_TEST } from '../constants/Test';

export const ScrollableText = (
  props: TextTickerProps & Readonly<{ children?: ReactNode }>,
) => {
  return (
    <TextTicker
      scrollSpeed={RUN_AS_TEST ? 0 : 10}
      loop={RUN_AS_TEST ? false : true}
      bounce={false}
      marqueeDelay={3000}
      repeatSpacer={200}
      {...props}
    />
  );
};
