import { StyleSheet, TouchableOpacity } from 'react-native';

import CustomText from './CustomText';
import { COLORS, isTablet } from '../constants';

interface TabNameProps {
  title: string;
  color: string;
  titleFontSize: number;
  titleLineHeight: number;
}

export function TabName({ title, color, titleFontSize, titleLineHeight }: TabNameProps) {
  return (
    <TouchableOpacity style={styles.tabTitleContainer}>
      <CustomText
        numberOfLines={1}
        style={[
          styles.tabTitle,
          {
            color,
            fontSize: titleFontSize,
            lineHeight: titleLineHeight,
          },
        ]}
        weight="Bold"
      >
        {title}
      </CustomText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  tabTitleContainer: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-start',
  },

  tabTitle: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
  },
});
