import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerScreenProps,
} from '@react-navigation/drawer';
import { useCallback } from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';
import { useDispatch } from 'react-redux';

import { LeftDrawerNavigator } from './LeftDrawerNavigator';
import { getUrlFromImageName } from '../api/images';
import { useAppSelector } from '../app/hooks';
import { ImageBackground } from '../components/ImageBackground';
import {
  DRAWER_SWIPE_EDGE_WIDTH,
  DRAWER_SWIPE_MIN_DISTANCE,
  isTablet,
} from '../constants';
import {
  selectAppConfig,
  selectFontFamily,
  selectMenuBackgroundColor,
  selectMenuBackgroundImage,
  selectMenuColor,
  selectRadioTab,
} from '../features/appconfig/store/selectors';
import { selectCurrentRadio } from '../features/currentradio/store/selectors';
import { updateCurrentRadio } from '../features/currentradio/store/slice';
import { isLastIndex } from '../helpers/array';
import { getFontFamily } from '../helpers/fontMapper';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import useDrawerWidth from '../hooks/useDrawerWidth';
import { StreamModel } from '../models';

export type RightDrawerNavigatorParams = {
  LeftDrawer: undefined;
};

export type RightDrawerNavigatorScreenProps<
  Screen extends keyof RightDrawerNavigatorParams,
> = DrawerScreenProps<RightDrawerNavigatorParams, Screen>;

const Drawer = createDrawerNavigator<RightDrawerNavigatorParams>();

function CustomDrawerContent(props: DrawerContentComponentProps) {
  const dispatch = useDispatch();
  const { navigation } = props;
  const appConfig = useAppSelector(selectAppConfig);
  const currentRadio = useAppSelector(selectCurrentRadio);
  const radioTab = useAppSelector(selectRadioTab);
  const fontFamily = useAppSelector(selectFontFamily);

  // Dynamic font size adjustments
  const drawerLabelFontSize = useAdjustedFontSize(isTablet ? mvs(18) : mvs(14));
  const drawerLabelActiveFontSize = useAdjustedFontSize(isTablet ? mvs(24) : mvs(18));

  const selectStream = useCallback(
    async (flux: StreamModel) => {
      if (flux.id !== currentRadio.id) {
        dispatch(updateCurrentRadio(flux));
      }
      navigation.closeDrawer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRadio, dispatch, navigation, radioTab],
  );

  return (
    <ImageBackground
      source={{ uri: props.backgroundImage }}
      style={[
        { backgroundColor: props.backgroundImageColor },
        styles.drawerContentScrollView,
        styles.container,
      ]}
      resizeMode="cover"
    >
      <SafeAreaView edges={['top']}>
        <DrawerContentScrollView {...props}>
          {props.streams.map((flux: StreamModel, index: number) => {
            if (flux.logoappfield === null || flux.logoappfield === 'null') {
              return (
                <DrawerItem
                  key={flux.id}
                  label={flux.nomFlux}
                  focused={flux.id === currentRadio.id}
                  labelStyle={[
                    styles.drawerLabelItem,
                    {
                      color: props.menuColor,
                      fontFamily: getFontFamily(fontFamily, 'Regular'),
                      fontSize:
                        flux.id === currentRadio.id
                          ? drawerLabelActiveFontSize
                          : drawerLabelFontSize,
                    },
                  ]}
                  style={[
                    styles.drawerItem,
                    isLastIndex(props.streams, index) ? styles.drawerItemLastChild : {},
                  ]}
                  onPress={() => {
                    selectStream(flux);
                  }}
                />
              );
            } else {
              return (
                <TouchableOpacity
                  key={flux.id}
                  onPress={() => {
                    selectStream(flux);
                  }}
                  style={[
                    styles.drawerItem,
                    isLastIndex(props.streams, index) ? styles.drawerItemLastChild : {},
                    flux.id === currentRadio.id ? styles.drawerItemActive : {},
                  ]}
                >
                  <Image
                    source={{
                      uri: getUrlFromImageName(appConfig, flux.logoappfield),
                    }}
                    style={styles.image}
                  />
                </TouchableOpacity>
              );
            }
          })}
        </DrawerContentScrollView>
      </SafeAreaView>
    </ImageBackground>
  );
}

export const RightDrawerNavigator = () => {
  const appConfig = useAppSelector(selectAppConfig);
  const menuColor = useAppSelector(selectMenuColor);
  const menuBackgroundColor = useAppSelector(selectMenuBackgroundColor);
  const menuBackgroundImage = useAppSelector(selectMenuBackgroundImage);
  const radioTab = useAppSelector(selectRadioTab);

  return (
    <Drawer.Navigator
      initialRouteName="LeftDrawer"
      screenOptions={{
        headerShown: false,
        drawerPosition: 'right',
        drawerType: 'front',
        swipeEdgeWidth: DRAWER_SWIPE_EDGE_WIDTH,
        swipeMinDistance: DRAWER_SWIPE_MIN_DISTANCE,
        drawerStyle: [styles.drawer, { width: useDrawerWidth() }],
      }}
      drawerContent={props => (
        <CustomDrawerContent
          {...props}
          backgroundImage={getUrlFromImageName(appConfig, menuBackgroundImage)}
          backgroundImageColor={menuBackgroundColor}
          menuColor={menuColor}
          streams={radioTab.flux}
        />
      )}
    >
      <Drawer.Screen name="LeftDrawer" component={LeftDrawerNavigator} />
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  drawer: {
    width: 200,
  },

  drawerContentScrollView: {
    paddingHorizontal: mvs(20),
  },

  image: {
    aspectRatio: 552 / 248, // Ratio connu de l'image reçu
    margin: mvs(10),
  },

  drawerItemActive: {
    backgroundColor: '#0002',
  },

  drawerItem: {
    paddingVertical: mvs(10),
    borderBottomWidth: 1,
    borderBottomColor: '#d9d9d9',
    backgroundColor: 'transparent',
  },

  drawerItemLastChild: {
    borderBottomWidth: 0,
  },

  container: {
    flex: 1,
    paddingTop: 20,
  },
});
