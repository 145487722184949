export enum TabType {
  Radio = 'radio',
  AudioDedication = 'dedicacesaudio',
  Alarm = 'reveil',
  Html = 'html',
  Dedication = 'dedicaces',
  LiveFeed = 'livefeed',
  Rss = 'rss',
  Website = 'site',
}

interface TabModel {
  idonglets: number;
  headerColor: string;
  headerTextColor: string;
  icon: string;
  titre: string;
  type: TabType;
  faIcon: string;
}

export type DedicationTabModel = TabModel; // No specific model
export type LiveFeedTabModel = TabModel; // No specific model

export interface WebsiteTabModel extends TabModel {
  urlSite: string;
}

export interface RssTabModel extends TabModel {
  urlFlux: string;
}

export interface HtmlTabModel extends TabModel {
  codeHTML: string;
  backgroundColor: string;
}

export interface AlarmClockTabModel extends TabModel {
  backgroundColor: string;
  backgroundreveilappfield: string;
  backgroundreveiliphone5appfield: string;
  backgroundreveiliphoneXappfield: string;
}

export interface AudioDedicationTabModel extends TabModel {
  idradio: number;
  foregroundColor: string;
  backgroundColor: string;
  welcomeMessage: string;
  endMessage: string;
}

export interface RadioTabModel extends TabModel {
  achatOption: 'YES' | 'NO';
  artistLabelColor: string;
  autoPlay: string;
  backgroundColor: string;
  backgroundColorSecondary: string;
  backgroundType: 'solid' | 'gradient' | 'bicolor';
  backgroundappfield: string;
  backgroundiphoneXappfield: string;
  bottomColor: string;
  callOption: 'YES' | 'NO';
  coverBorderColor: string;
  divRadius: number;
  divShadow: number;
  emailAddress: string;
  emailOption: 'YES' | 'NO';
  fondTitrage: 'YES' | 'NO';
  fontFamily: FontFamily;
  fontSize: FontSizeLevel;
  gradientDirection: GradientDirection;
  iconStyle: 'solid' | 'regular';
  itunesId: string;
  logoappfield: string;
  nomColor: string;
  partage: 'YES' | 'NO';
  phoneNumber: string;
  pochettedefaut: string;
  showBlurredCover: 'YES' | 'NO';
  blurredBackground: 'YES' | 'NO';
  showCoverBorder: 'YES' | 'NO';
  sleeptimerOption: 'YES' | 'NO';
  smsNumber: string;
  smsOption: 'YES' | 'NO';
  theme: string;
  titleLabelColor: string;
  voteOption: 'YES' | 'NO';
  flux: StreamModel[];
  coverType: 'square' | 'rounded-square' | 'circle';
  mainColor: string;
  alwaysDefaultCover: 'YES' | 'NO';
  textAlign: TextAlign;
  buttonStyle: ButtonStyle;
  divWithMargin: 'YES' | 'NO';
}

export interface StreamModel {
  id: number;
  logoappfield: string;
  nomFlux: string;
  typeTitrage: string;
  urlFlux: string;
  urlFluxBD: string;
  urlFluxHD: string;
  idradio: number;
}

export type GradientDirection = 'bottom' | 'bottom-right' | 'right' | 'bottom-left';
export type FontFamily =
  | 'Comfortaa'
  | 'DancingScript'
  | 'EBGaramond'
  | 'Fedroka'
  | 'JosefinSans'
  | 'Oswald'
  | 'PlayfairDisplay'
  | 'Poppins'
  | 'Quicksand'
  | 'Raleway'
  | 'Roboto'
  | 'RobotoMono'
  | 'Solway';

export type TextAlign = 'left' | 'right' | 'center' | 'justify';
export type ButtonStyle = 'primary' | 'secondary';
export type FontSizeLevel = 1 | 2 | 3 | 4 | 5;
