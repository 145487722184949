import { useState } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { CustomIcon, CustomIconProps } from './CustomIcon';
import CustomText from './CustomText';
import { IS_IOS, isTablet } from '../constants';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import { useButtonColors } from '../hooks/useButtonColors';
import { useShadowStyles } from '../hooks/useShadowStyles';

interface Props {
  icon: CustomIconProps['name'];
  iconSize?: CustomIconProps['size'];
  iconColor?: CustomIconProps['color'];
  onPress?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  hidden?: boolean;
  disabled?: boolean;
  text?: string;
}

export const FloatingActionButton = (props: Props) => {
  const { gradientBackgroundColours, iconOrTextColor } = useButtonColors();
  const shadowStyles = useShadowStyles();
  const [shadow, setShadow] = useState(true);

  if (props.hidden) {
    return null;
  }

  const isTextButton = props.text && isTablet;
  const textFontSize = useAdjustedFontSize(mvs(18));

  return (
    <View style={[styles.container, IS_IOS && shadowStyles, props.containerStyle]}>
      <TouchableOpacity
        onPress={props.onPress}
        hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
        disabled={props.disabled}
        onPressIn={() => setShadow(false)} //TODO Fix de React 0.76.6
        onPressOut={() => setShadow(true)}
      >
        <LinearGradient
          // gradient initilalized with a single color.
          // prepared for future addition of a secondary color.
          colors={gradientBackgroundColours}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
          style={[
            styles.button,
            {
              width: isTextButton ? 'auto' : 52,
              height: isTextButton ? mvs(52) : 52,
              borderRadius: isTextButton ? mvs(26) : 26,
            },
            !IS_IOS && shadow && shadowStyles,
            props.buttonStyle,
          ]}
        >
          <View
            style={[
              styles.buttonContainer,
              {
                paddingRight: isTextButton ? mvs(30) : 0,
                // offset to compensate for default empty space around icon
                paddingLeft: isTextButton ? mvs(25) : 0,
              },
            ]}
          >
            <CustomIcon
              name={props.icon}
              size={props.iconSize ?? 24}
              color={iconOrTextColor}
            />
            {isTextButton && (
              <CustomText
                style={[styles.text, { color: iconOrTextColor, fontSize: textFontSize }]}
                weight="SemiBold"
              >
                {props.text}
              </CustomText>
            )}
          </View>
        </LinearGradient>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: isTablet ? mvs(24) : 24,
    bottom: isTablet ? mvs(24) : 24,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginLeft: mvs(5),
    marginBottom: 4,
  },
});
