import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { RecordButtonIcon } from './RecordButtonIcon';
import { IS_IOS } from '../constants';
import { useShadowStyles } from '../hooks/useShadowStyles';
import { UseToggleRecording } from '../hooks/useToggleRecording';

interface Props
  extends Pick<
    UseToggleRecording,
    'isRecording' | 'isRecorded' | 'toggleRecording' | 'countdown'
  > {
  style?: StyleProp<ViewStyle>;
  iconSize?: number;
}

export const RecordButton = ({
  isRecording,
  isRecorded,
  toggleRecording,
  countdown,
  style,
  iconSize,
}: Props) => {
  const shadowStyles = useShadowStyles();
  return (
    <TouchableOpacity
      hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
      style={[style, IS_IOS && shadowStyles]}
      onPress={() => toggleRecording()}
    >
      <RecordButtonIcon
        isShown={!isRecorded}
        iconSize={iconSize}
        isRecording={isRecording || countdown > 0}
      />
    </TouchableOpacity>
  );
};
