// MenuIcon.js
import { mvs } from 'react-native-size-matters';

import { CustomIcon } from './CustomIcon';

interface MenuIconProps {
  color: string;
  toggleLeftDrawer: () => void;
}

export function MenuIcon({ color, toggleLeftDrawer }: MenuIconProps) {
  return (
    <CustomIcon
      name="icon_menu"
      color={color}
      size={mvs(24)}
      onPress={toggleLeftDrawer}
    />
  );
}
