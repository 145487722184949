import { useAppSelector } from '../app/hooks';
import { IS_WEB } from '../constants';
import { selectDivShadow } from '../features/appconfig/store/selectors';

export const useShadowStyles = () => {
  const divShadow = useAppSelector(selectDivShadow);
  const shadowOpacity = divShadow / 100;

  if (IS_WEB) {
    return {
      boxShadow: `-4px 8px 30px rgba(0, 0, 0, ${shadowOpacity})`,
    };
  }

  const elevation = divShadow / 4;

  return {
    shadowColor: '#000',
    shadowOffset: {
      width: -4,
      height: 4,
    },
    shadowOpacity,
    shadowRadius: 30,
    elevation,
  };
};
