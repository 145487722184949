import { DrawerActions } from '@react-navigation/native';
import { Image } from '@rneui/themed';
import { decode } from 'html-entities';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Pressable,
  RefreshControl,
  StyleSheet,
  View,
} from 'react-native';
import base64 from 'react-native-base64';
import { mvs } from 'react-native-size-matters';
import GestureRecognizer from 'react-native-swipe-gestures';

import RssItemModalScreen from './RssItemModalScreen';
import { getRssContent } from '../api/mobileApi';
import { useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { MenuIcon } from '../components/MenuIcon';
import { ScreenLayout } from '../components/ScreenLayout';
import { TabName } from '../components/TabName';
import { COLORS, IS_WEB, isTablet, REDUCED_HORIZONTAL_MARGIN } from '../constants';
import {
  selectAppConfig,
  selectDivRadius,
  selectHasMargin,
  selectTextAlign,
} from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import { useShadowStyles } from '../hooks/useShadowStyles';
import { formatFromNow } from '../localization/datetime';
import { RssItemModel, RssTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';

export default function RssScreen({
  route,
  navigation,
}): LeftDrawerNavigatorScreenProps<'Rss'> {
  const tabOptions = route.params.options as RssTabModel;
  const rssListRef = useRef<FlatList<RssItemModel>>(null);
  const appConfig = useAppSelector(selectAppConfig);
  const [rssItems, setRssItems] = useState([]);
  const [rssItemVisible, setRssItemVisible] = useState(false);
  const [currentRssItem, setCurrentRssItem] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const bearerToken = useAppSelector(selectBearerToken);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const rssItemMobileMarginHorizontal = hasMargin ? 34 : REDUCED_HORIZONTAL_MARGIN;
  const otherItemMobileMarginLeft = hasMargin ? 54 : REDUCED_HORIZONTAL_MARGIN + 20;

  // dynamic font sizes
  const tabTitleFontSize = useAdjustedFontSize(mvs(25));
  const tabTitleLineHeight = useAdjustedFontSize(mvs(30));
  const itemTitleFontSize = useAdjustedFontSize(isTablet ? mvs(16, 0.4) : 20);
  const itemDateFontSize = useAdjustedFontSize(isTablet ? mvs(9) : mvs(12, 0.4));
  const itemContentFontSize = useAdjustedFontSize(mvs(11, 0.4));
  const otherItemTitleFontSize = useAdjustedFontSize(isTablet ? mvs(10) : 16);
  const itemDateHeight = useAdjustedFontSize(mvs(17, 0.4));

  const formatHTMLContent = useCallback(
    (content: string) =>
      content &&
      decode(
        content
          // TO DO: combine regex
          // removes HTML tags
          .replace(/<[^>]+>/g, '')
          // removes line breaks
          .replace(/(\r\n|\n|\r)/gm, ''),
      ),
    [],
  );

  const rssListKeyExtractor = useCallback((item: RssItemModel) => `${item.date}`, []);

  const fetchRssItems = useCallback(
    page => {
      if (IS_WEB && !tabOptions.urlFlux) {
        setRefreshing(false);
        return;
      }
      getRssContent(
        base64.encode(tabOptions.urlFlux),
        page,
        bearerToken,
        appConfig.idapplications,
      ).then(response => {
        setRefreshing(false);
        setRssItems(response.data);
        setCurrentPage(response.current_page);
        setLastPage(response.last_page);
      });
    },
    [appConfig, bearerToken, tabOptions],
  );

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const loadNextPage = useCallback(() => {
    if (currentPage !== lastPage) {
      getRssContent(
        base64.encode(tabOptions.urlFlux),
        currentPage + 1,
        bearerToken,
        appConfig.idapplications,
      ).then(response => {
        setCurrentPage(currentPage + 1);
        setRssItems(rssItems.concat(response.data));
      });
    }
  }, [appConfig, bearerToken, currentPage, lastPage, rssItems, tabOptions]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchRssItems(1);
  }, [fetchRssItems]);

  useEffect(() => {
    fetchRssItems(1);
  }, [fetchRssItems]);

  // loads 2 pages instead of 1 by default on tablet
  useEffect(() => {
    if (rssItems.length !== 0 && currentPage === 1 && isTablet) {
      loadNextPage();
    }
  }, [rssItems, currentPage, loadNextPage]);

  const firstRssItem = useMemo(() => {
    if (rssItems && rssItems[0] !== undefined) {
      const item: RssItemModel = rssItems[0];
      return (
        <Pressable
          onPress={() => {
            setRssItemVisible(true);
            setCurrentRssItem(item);
          }}
        >
          <View
            style={[
              styles.firstItem,
              {
                borderRadius: mvs(divRadius),
                marginHorizontal: isTablet ? mvs(32) : rssItemMobileMarginHorizontal,
              },
            ]}
          >
            <View style={styles.itemTextContainer}>
              <CustomText
                style={[
                  styles.itemDate,
                  { fontSize: itemDateFontSize, height: itemDateHeight },
                ]}
                numberOfLines={1}
                weight="Regular"
                textAlign={textAlign}
              >
                {formatFromNow(new Date(item.date), { addSuffix: true })}
              </CustomText>
              <CustomText
                numberOfLines={3}
                style={[styles.itemTitle, { fontSize: itemTitleFontSize }]}
                weight="Regular"
                textAlign={textAlign}
              >
                {decode(item.title)}
              </CustomText>
              {isTablet && (
                <CustomText
                  weight="Regular"
                  numberOfLines={2}
                  style={[styles.itemContent, { fontSize: itemContentFontSize }]}
                  textAlign={textAlign}
                >
                  {formatHTMLContent(item.content)}
                </CustomText>
              )}
            </View>
            <View style={styles.itemImageContainer}>
              <Image
                source={{ uri: item.image }}
                resizeMode="cover"
                style={[styles.itemPicture, { borderRadius: mvs(divRadius) }]}
              />
            </View>
          </View>
        </Pressable>
      );
    } else {
      return <></>;
    }
  }, [
    rssItems,
    divRadius,
    rssItemMobileMarginHorizontal,
    itemDateFontSize,
    itemDateHeight,
    textAlign,
    itemTitleFontSize,
    itemContentFontSize,
    formatHTMLContent,
  ]);

  const renderOtherRssItem = useCallback(
    ({ item: item }: ListRenderItemInfo<RssItemModel>) => {
      if (rssItems !== undefined) {
        return (
          <Pressable
            onPress={() => {
              setRssItemVisible(true);
              setCurrentRssItem(item);
            }}
            style={styles.otherItemContainer}
          >
            <View
              style={[
                styles.otherItem,
                {
                  borderRadius: mvs(divRadius),
                  marginRight: isTablet ? 0 : rssItemMobileMarginHorizontal,
                  marginLeft: isTablet ? mvs(15) : otherItemMobileMarginLeft,
                },
              ]}
            >
              <CustomText
                style={[
                  styles.itemDate,
                  { fontSize: itemDateFontSize, height: itemDateHeight },
                ]}
                numberOfLines={1}
                weight="Regular"
                textAlign={textAlign}
              >
                {formatFromNow(new Date(item.date), { addSuffix: true })}
              </CustomText>
              <CustomText
                numberOfLines={3}
                style={[styles.otherItemTitle, { fontSize: otherItemTitleFontSize }]}
                weight="Regular"
                textAlign={textAlign}
              >
                {decode(item.title)}
              </CustomText>
            </View>
            <View style={[styles.otherItemPictureParent, !IS_WEB && shadowStyles]}>
              <Image
                source={{ uri: item.image }}
                resizeMode="cover"
                style={[
                  styles.itemPicture,
                  styles.otherItemPicture,
                  {
                    borderRadius: mvs(divRadius),
                    marginLeft: isTablet ? 0 : rssItemMobileMarginHorizontal,
                  },
                ]}
              />
            </View>
          </Pressable>
        );
      } else {
        return <></>;
      }
    },
    [
      rssItems,
      divRadius,
      rssItemMobileMarginHorizontal,
      otherItemMobileMarginLeft,
      itemDateFontSize,
      itemDateHeight,
      textAlign,
      otherItemTitleFontSize,
      shadowStyles,
    ],
  );

  return (
    <ScreenLayout
      headerContents={
        <>
          <MenuIcon
            color={tabOptions.headerTextColor}
            toggleLeftDrawer={toggleLeftDrawer}
          />
          <TabName
            title={tabOptions.titre}
            color={tabOptions.headerTextColor}
            titleFontSize={tabTitleFontSize}
            titleLineHeight={tabTitleLineHeight}
          />
        </>
      }
    >
      <View style={styles.container}>
        <FlatList
          ref={rssListRef}
          // removes first item of list
          data={rssItems !== undefined ? rssItems.slice(1) : []}
          renderItem={renderOtherRssItem}
          ListHeaderComponent={firstRssItem}
          ListHeaderComponentStyle={styles.firstItemContainer}
          keyExtractor={rssListKeyExtractor}
          showsVerticalScrollIndicator={false}
          columnWrapperStyle={isTablet && styles.columnWrapperStyle}
          numColumns={isTablet ? 2 : 1}
          onEndReached={loadNextPage}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
      </View>
      <GestureRecognizer
        onSwipeDown={() => setRssItemVisible(false)}
        config={{
          velocityThreshold: 3,
        }}
      >
        <RssItemModalScreen
          item={currentRssItem}
          visible={rssItemVisible}
          onCancel={() => {
            setRssItemVisible(false);
          }}
        />
      </GestureRecognizer>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  firstItemContainer: {
    width: isTablet ? '100%' : 'auto',
  },
  firstItem: {
    flexDirection: isTablet ? 'row-reverse' : 'column',
    backgroundColor: COLORS.WHITE,
    paddingTop: isTablet ? mvs(20) : 20,
    paddingBottom: isTablet ? mvs(20) : mvs(32),
    paddingHorizontal: isTablet ? mvs(20) : mvs(31),
    marginBottom: isTablet ? mvs(30) : mvs(12),
  },

  itemTextContainer: {
    flex: IS_WEB ? undefined : 1,
    marginLeft: isTablet ? mvs(8) : 0,
    paddingRight: isTablet ? mvs(25) : 0,
  },

  itemImageContainer: {
    flex: 1,
    marginRight: isTablet ? mvs(15) : 0,
  },

  itemTitle: {
    color: isTablet ? '#474747' : COLORS.BLACK_80,
    lineHeight: isTablet ? mvs(18) : 24,
    marginTop: isTablet ? mvs(1) : 3,
  },
  itemDate: {
    color: COLORS.BLACK_50,
    marginTop: isTablet ? mvs(4) : 0,
  },
  itemPicture: {
    flexShrink: 1,
    aspectRatio: 9 / 5,
    marginTop: isTablet ? 0 : 7,
  },
  itemContent: {
    marginTop: mvs(3),
    color: '#474747',
  },
  columnWrapperStyle: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: isTablet ? mvs(32) : mvs(34),
  },
  otherItemContainer: {
    width: isTablet ? '48.5%' : '100%',
  },
  otherItem: {
    backgroundColor: COLORS.WHITE,
    paddingTop: isTablet ? mvs(8) : 24,
    paddingBottom: isTablet ? mvs(8) : 26,
    paddingRight: isTablet ? mvs(50) : 31,
    paddingLeft: isTablet ? mvs(60) : 78,
    marginBottom: isTablet ? mvs(12) : 12,
  },

  otherItemTitle: {
    height: isTablet ? mvs(50) : IS_WEB ? 'auto' : 70,
    minHeight: IS_WEB ? 50 : undefined,
    color: isTablet ? '#474747' : undefined,
    lineHeight: isTablet ? mvs(13) : undefined,
  },

  otherItemPicture: {
    aspectRatio: 1,
    width: isTablet ? mvs(65) : 80,
    height: isTablet ? mvs(65) : 80,
    borderColor: '#F3F3F3',
    borderWidth: isTablet ? mvs(4) : 4,
    marginTop: isTablet ? mvs(8) : 28,
  },

  otherItemPictureParent: {
    position: 'absolute',
  },
});
