import { DrawerActions } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mvs } from 'react-native-size-matters';
import { WebView } from 'react-native-webview';

import { MenuIcon } from '../components/MenuIcon';
import { ScreenLayout } from '../components/ScreenLayout';
import { TabName } from '../components/TabName';
import { COLORS, IS_WEB } from '../constants';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import i18n from '../localization/i18n';
import { WebsiteTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';

export default function WebsiteScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'Website'>) {
  const tabOptions = route.params.options as WebsiteTabModel;
  const [currentUrl, setCurrentUrl] = useState(tabOptions.urlSite);

  // Dynamic font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));
  const defaultWebviewMessageFontSize = useAdjustedFontSize(mvs(20));

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const handleShouldStartLoad = (request: any) => {
    const { url } = request;
    if (url.startsWith('intent://')) {
      try {
        const fallbackUrl = decodeURIComponent(
          url.split('browser_fallback_url=')[1]?.split(';')[0],
        );
        if (fallbackUrl) {
          setCurrentUrl(fallbackUrl);
        }
      } catch (error) {}
      return false;
    }
    return true;
  };

  return (
    <ScreenLayout
      headerContents={
        <>
          <MenuIcon
            color={tabOptions.headerTextColor}
            toggleLeftDrawer={toggleLeftDrawer}
          />
          <TabName
            title={tabOptions.titre}
            color={tabOptions.headerTextColor}
            titleFontSize={titleFontSize}
            titleLineHeight={titleLineHeight}
          />
        </>
      }
    >
      {IS_WEB && (
        <View style={styles.defaultWebview}>
          <Text
            style={[
              styles.defaultWebviewMessage,
              { fontSize: defaultWebviewMessageFontSize },
            ]}
          >
            {i18n.t('webview.previewNotAvailable')}
          </Text>
        </View>
      )}
      {!IS_WEB && (
        <WebView
          style={styles.webview}
          source={{ uri: currentUrl }}
          allowsFullscreenVideo={true}
          allowsInlineMediaPlayback={true}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          originWhitelist={['http://', 'https://', 'about:', 'intent://']}
          onShouldStartLoadWithRequest={handleShouldStartLoad}
        />
      )}
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  webview: {
    flexShrink: 0,
  },
  defaultWebview: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: COLORS.WHITE,
  },
  defaultWebviewMessage: {
    textAlign: 'center',
    paddingHorizontal: 30,
  },
});
