import { ReactNode } from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';
import styled from 'styled-components/native';

import { COLORS } from '../constants';
import { useButtonColors } from '../hooks/useButtonColors';

interface PlayPauseIconContainerProps extends ViewProps {
  children: ReactNode;
}

export const PlayPauseIconContainer = ({
  style,
  children,
}: PlayPauseIconContainerProps) => {
  const { buttonColors, buttonStyle, gradientBackgroundColours } = useButtonColors();

  const buttonDynamicStyles =
    buttonStyle === 'primary'
      ? {
          borderColor: COLORS.WHITE,
          borderWidth: 5,
        }
      : {
          borderColor: buttonColors.secondaryColor,
          borderWidth: 0,
        };

  return (
    <Button style={[style, buttonDynamicStyles]}>
      <LinearGradient
        colors={gradientBackgroundColours}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={styles.background}
      >
        {children}
      </LinearGradient>
    </Button>
  );
};

const buttonWidth = mvs(58);

const Button = styled.View`
  width: ${buttonWidth}px;
  aspect-ratio: 1;
  border-radius: ${mvs(44)}px;
  border-width: 5px;
  align-self: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: buttonWidth,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
