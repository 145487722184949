import { useCallback } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { mvs } from 'react-native-size-matters';

import { CustomIcon } from './CustomIcon';
import CustomText from './CustomText';
import { useAppSelector } from '../app/hooks';
import { COLORS , isTablet } from '../constants';
import { selectMainColor } from '../features/appconfig/store/selectors';
import i18n from '../localization/i18n';

interface Props {
  value: Array<boolean>;
  setDays: (days: Array<boolean>) => void;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export const days = [
  'day.monday',
  'day.tuesday',
  'day.wednesday',
  'day.thursday',
  'day.friday',
  'day.saturday',
  'day.sunday',
];

export const DayMultiselect = (props: Props) => {
  const mainColor = useAppSelector(selectMainColor);

  const dayItem = useCallback(
    (day: string) => {
      const index = days.indexOf(day);
      return (
        <View style={[styles.line, day === 'day.sunday' ? styles.lastItem : {}]}>
          <TouchableOpacity
            key={index}
            style={styles.listItem}
            onPress={() => {
              const tmpArrayResult: Array<boolean> = [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              ];
              for (let i = 0; i < 7; i++) {
                tmpArrayResult[i] = i === index ? !props.value[i] : props.value[i];
              }
              props.setDays(tmpArrayResult);
            }}
            hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
          >
            <CustomText style={[styles.text, props.textStyle]} weight="Regular">
              {i18n.t(day)}
            </CustomText>
            <View
              // eslint-disable-next-line react-native/no-inline-styles
              style={[styles.check, { display: props.value[index] ? 'flex' : 'none' }]}
            >
              <CustomIcon name="icon_check" color={mainColor} size={mvs(23)} />
            </View>
          </TouchableOpacity>
        </View>
      );
    },
    [mainColor, props],
  );

  return <View style={styles.container}>{days.map(i => dayItem(i))}</View>;
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    borderRadius: mvs(10),
    backgroundColor: COLORS.WHITE,
    paddingHorizontal: mvs(17),
    paddingVertical: mvs(7),
    marginVertical: isTablet ? mvs(36) : mvs(16),
  },
  listItem: {
    paddingVertical: mvs(10),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lastItem: {
    borderBottomWidth: 0,
  },
  text: {
    color: '#474747',
    fontSize: isTablet ? mvs(16) : 20,
  },
  line: {
    borderBottomWidth: mvs(1),
    borderBottomColor: '#EEEEEE',
  },
  check: {
    alignSelf: 'center',
    display: 'none',
  },
});
