import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { mvs } from 'react-native-size-matters';

import { useAppSelector } from '../app/hooks';
import { WINDOW_HEIGHT, WINDOW_WIDTH } from '../constants';
import { coverBorderColor, showCoverBorder } from '../features/appconfig/store/selectors';

interface Props {
  source: ImageSourcePropType;
  sizeStyle?: StyleProp<ViewStyle>;
  coverType?: string;
}

export const CoverImage = (props: Props) => {
  const getBorderRadius = () => {
    switch (props.coverType) {
      case 'square':
        return 0;
      case 'rounded-square':
        return 50;
      case 'circle':
        return 2000;
      default:
        return 0;
    }
  };

  const hasCoverBorder = useAppSelector(showCoverBorder);
  const borderColor = useAppSelector(coverBorderColor);

  return (
    <View style={[styles.container, props.sizeStyle]}>
      <Image
        source={props.source}
        style={[styles.image, { borderRadius: getBorderRadius() }]}
        resizeMode="cover"
      />
      {hasCoverBorder && (
        <View
          pointerEvents="none" // important pour laisser passer les interactions
          style={[
            StyleSheet.absoluteFillObject,
            {
              borderRadius: getBorderRadius(),
              borderWidth: mvs(7),
              borderColor: borderColor,
            },
          ]}
        />
      )}
    </View>
  );
};

const screenRatio = WINDOW_HEIGHT / WINDOW_WIDTH;

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    // avoids track details section below to by squashed on wider smartphones
    marginHorizontal: screenRatio < 1.7 ? mvs(20) : 0,
  },
  image: {
    aspectRatio: 1,
    height: undefined, // https://reactnative.dev/docs/images#static-image-resources
    width: undefined,
  },
});
