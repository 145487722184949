import Slider from '@react-native-community/slider';
import { decode } from 'html-entities';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Linking,
  Modal,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import { defaultSystemFonts, RenderHTML } from 'react-native-render-html';
import { mvs } from 'react-native-size-matters';
import ReactPlayer from 'react-player';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button } from '../components/Button';
import { CustomIcon, CustomIconName } from '../components/CustomIcon';
import CustomText from '../components/CustomText';
import { FloatingActionButton } from '../components/FloatingActionButton';
import { FloatingExitButton } from '../components/FloatingExitButton';
import { COLORS , IS_IOS, isTablet, WINDOW_WIDTH } from '../constants';
import {
  selectDivRadius,
  selectFontFamily,
  selectTextAlign,
} from '../features/appconfig/store/selectors';
import { showAlert } from '../features/modal/store/slice';
import { generateFontVariantsForRenderingRawHTML } from '../helpers/fontMapper';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import { useShadowStyles } from '../hooks/useShadowStyles';
import { formatFromNow } from '../localization/datetime';
import i18n from '../localization/i18n';
import { RssItemModel } from '../models';

export interface RssItemModalScreenProps {
  onCancel: () => void;
  visible?: boolean;
  item: RssItemModel;
}

export default function RssItemModalScreen({
  onCancel,
  visible = false,
  item,
}: RssItemModalScreenProps) {
  const [durationMillis, setDurationMillis] = React.useState(1);
  const [positionMillis, setPositionMillis] = React.useState(0);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [iconPlayerAudio, setIconPlayerAudio] = React.useState('icon_play_with_offset');
  const [loaderVisible, setLoaderVisible] = React.useState(true);
  const windowWidth = useWindowDimensions().width;
  const fontFamily = useAppSelector(selectFontFamily);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  const selectedFontVariants = generateFontVariantsForRenderingRawHTML(fontFamily);

  const systemFonts = [...defaultSystemFonts, ...selectedFontVariants];

  const [isStarted, setIsStarted] = useState(false);
  const dispatch = useAppDispatch();
  const divRadius = useAppSelector(selectDivRadius);

  useEffect(() => {
    /*if (item && item.audio && visible) {
      setAudioRecorderPlayer(new AudioRecorderPlayer());
      setIconPlayerAudio('icon_play_with_offset');
    } else {
      setAudioRecorderPlayer(null);
    }*/
  }, [visible, item]);

  const changeAudioTime = useCallback((value: number) => {
    /*setSliderValue(value);
      const newPositionMillis = value * durationMillis;
      audioRecorderPlayer?.seekToPlayer(newPositionMillis);
      audioRecorderPlayer?.resumePlayer();
      setIconPlayerAudio('icon_pause');*/
  }, []);
  // dynamic font sizes
  const itemTitleFontSize = useAdjustedFontSize(isTablet ? mvs(17) : 20);
  const itemDateFontSize = useAdjustedFontSize(isTablet ? mvs(12) : 12);
  const itemDateLineHeight = useAdjustedFontSize(isTablet ? mvs(18) : 16.37);
  const sliderTextFontSize = useAdjustedFontSize(isTablet ? mvs(13) : 13);
  const paragraphFontSize = useAdjustedFontSize(isTablet ? mvs(12) : 15);
  const paragraphLineHeight = useAdjustedFontSize(isTablet ? mvs(15) : 15);

  const startPlayer = useCallback(async () => {
    /*try {
      PlayerService.pause();
      await audioRecorderPlayer?.startPlayer(item.audio);
      setIsPlaying(true);
      setIconPlayerAudio('icon_pause');
      audioRecorderPlayer?.setSubscriptionDuration(0.5);
      audioRecorderPlayer?.addPlayBackListener(e => {
        onPlaybackStatusUpdate(e);
      });
    } catch (error) {
      console.error(error);
    }*/
  }, []);

  const resumePlayer = useCallback(async () => {
    /*audioRecorderPlayer?.resumePlayer();
    setIsPlaying(true);
    setIconPlayerAudio('icon_pause');*/
  }, []);

  const pausePlayer = useCallback(async () => {
    /*audioRecorderPlayer?.pausePlayer();
    setIsPlaying(false);
    setIconPlayerAudio('icon_play_with_offset');*/
  }, []);

  const stopPlayer = useCallback(async () => {
    setIsStarted(false);
    setIconPlayerAudio('icon_play_with_offset');
    /*audioRecorderPlayer?.removePlayBackListener();
    audioRecorderPlayer?.stopPlayer();
    setAudioRecorderPlayer(null);
    setPositionMillis(0);
    setDurationMillis(0);
    setSliderValue(0);
    setIsPlaying(false);
    setIconPlayerAudio('icon_play_with_offset');*/
  }, []);

  const togglePlayPause = useCallback(async () => {
    setIsStarted(!isStarted);
    if (isStarted) {
      setIconPlayerAudio('icon_play_with_offset');
    } else {
      setIconPlayerAudio('icon_pause');
    }
    /*if (item?.audio && audioRecorderPlayer) {
      if (isPlaying) {
        pausePlayer();
      } else {
        if (positionMillis > 0) {
          resumePlayer();
        } else {
          startPlayer();
        }
      }
    }*/
  }, [isStarted]);

  const closeModal = useCallback(async () => {
    stopPlayer();
    onCancel();
  }, [onCancel, stopPlayer]);

  const shareItem = useCallback(() => {
    dispatch(
      showAlert({
        key: 'only-mobile',
        type: 'info',
        text: i18n.t('alert.onlyMobile'),
        closable: false,
        autoCloseAfterMs: 2000,
      }),
    );
  }, [dispatch]);

  const displayTimeFromMillisenconds = (time: number) => {
    const pad = (n: number) => {
      return ('00' + n).slice(-2);
    };
    const ms = time % 1000;
    time = (time - ms) / 1000;
    const secs = time % 60;
    time = (time - secs) / 60;
    const mins = time % 60;
    time = (time - mins) / 60;
    const hours = time;
    if (hours > 0) {
      return pad(hours) + ':' + pad(mins) + ':' + pad(secs);
    }
    return pad(mins) + ':' + pad(secs);
  };

  const slider = useMemo(() => {
    if (item === undefined) {
      return <></>;
    }
    return (
      <View style={styles.sliderContainer}>
        <ReactPlayer
          url={item.audio}
          playing={isStarted}
          width="0"
          height="0"
          style={{ display: 'none' }}
          audioonly="true"
        />
        <CustomIcon
          name={iconPlayerAudio as CustomIconName}
          onPress={() => togglePlayPause()}
          style={styles.playerButton}
          color={COLORS.WHITE}
          size={mvs(25)}
        />
        <Slider
          minimumValue={0}
          maximumValue={1}
          value={sliderValue}
          minimumTrackTintColor="#fff"
          maximumTrackTintColor="rgba(255, 255, 255, 0.14)"
          thumbTintColor={COLORS.WHITE}
          style={styles.slider}
          onSlidingComplete={value => {
            changeAudioTime(value);
          }}
          onSlidingStart={() => {
            pausePlayer();
          }}
          tapToSeek={true}
        />
        <CustomText
          weight="Regular"
          style={[styles.sliderText, { fontSize: sliderTextFontSize }]}
        >
          {displayTimeFromMillisenconds(positionMillis) +
            ' / ' +
            displayTimeFromMillisenconds(durationMillis)}
        </CustomText>
      </View>
    );
  }, [
    item,
    isStarted,
    iconPlayerAudio,
    sliderValue,
    positionMillis,
    durationMillis,
    togglePlayPause,
    changeAudioTime,
    sliderTextFontSize,
  ]);

  const headerItem = useMemo(() => {
    if (item !== undefined) {
      if (item.video !== null) {
        return (
          <View>
            <ReactPlayer
              url={item.video}
              playing={isStarted}
              width="0"
              height="0"
              style={styles.itemVideo}
              audioonly="true"
            />
          </View>
        );
      } else if (item.audio) {
        return (
          <View>
            <Image
              source={{ uri: item.image }}
              resizeMode="cover"
              style={[styles.itemPicture, { borderRadius: mvs(divRadius) }]}
            />
            {slider}
          </View>
        );
      } else {
        return (
          <View>
            <Image
              source={{ uri: item.image }}
              resizeMode="cover"
              style={styles.itemPicture}
            />
          </View>
        );
      }
    }
    return null;
  }, [item, slider]);

  if (item === undefined) {
    return null;
  } else {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        statusBarTranslucent={true}
        onRequestClose={() => closeModal()}
      >
        <View
          style={[
            styles.modalView,
            shadowStyles,
            {
              width: isTablet ? windowWidth - mvs(36) : windowWidth - 22,
            },
          ]}
        >
          <FloatingExitButton
            onPress={() => closeModal()}
            containerStyle={styles.closeButton}
          />
          {isTablet && (
            <FloatingActionButton
              icon={'icon_share_with_offset'}
              iconSize={21}
              containerStyle={styles.tabletShareButtonContainer}
              buttonStyle={[styles.tabletShareButton, shadowStyles]}
              onPress={() => shareItem()}
              disabled
            />
          )}
          <ScrollView
            style={styles.modalBody}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            overScrollMode={'never'}
          >
            <View>{headerItem}</View>
            <CustomText
              style={[
                styles.itemDate,
                { fontSize: itemDateFontSize, lineHeight: itemDateLineHeight },
              ]}
              weight="Regular"
              textAlign={textAlign}
            >
              {formatFromNow(new Date(item.date), { addSuffix: true })}
              {item.author && ' - ' + item.author}
            </CustomText>
            <CustomText
              style={[styles.itemTitle, { fontSize: itemTitleFontSize }]}
              weight={isTablet ? 'SemiBold' : 'Regular'}
              textAlign={textAlign}
            >
              {decode(item.title)}
            </CustomText>
            {loaderVisible && <ActivityIndicator size="large" />}

            <RenderHTML
              contentWidth={WINDOW_WIDTH}
              source={{ html: item.content }}
              tagsStyles={{
                p: {
                  fontSize: paragraphFontSize,
                  color: isTablet ? '#474747' : undefined,
                  lineHeight: paragraphLineHeight,
                  fontFamily: selectedFontVariants[0],
                  textAlign: textAlign,
                },
                strong: {
                  fontFamily: selectedFontVariants[1],
                },
                body: {
                  fontFamily: selectedFontVariants[0],
                },
              }}
              systemFonts={systemFonts}
              onHTMLLoaded={() => {
                setLoaderVisible(false);
              }}
            />
            <Button
              style={[styles.showOnlineButton, { borderRadius: mvs(divRadius) }]}
              textStyle={[styles.showOnlineButtonText]}
              text={i18n.t('rssItem.showOnline')}
              onPress={() => Linking.openURL(item.url)}
            />
          </ScrollView>
          {!isTablet && (
            <FloatingActionButton
              icon={'icon_share_with_offset'}
              onPress={() => shareItem()}
              style={styles.shareButton}
              disabled
            />
          )}
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalView: {
    marginTop: 80,
    flex: 1,
    alignSelf: 'center',
    alignItems: isTablet ? 'center' : 'flex-start',
    backgroundColor: '#F2F2F6',
    borderTopLeftRadius: isTablet ? mvs(20) : 10,
    borderTopRightRadius: isTablet ? mvs(20) : 10,
    borderBottomLeftRadius: isTablet ? mvs(1) : 0, // fix issue on React Native 0.76.6
    borderBottomRightRadius: isTablet ? mvs(1) : 0, // fix issue on React Native 0.76.6
    paddingHorizontal: isTablet ? mvs(20) : 18,
    paddingTop: isTablet ? mvs(20) : 34,
    paddingBottom: isTablet ? mvs(12) : 12,
  },

  modalBody: {
    flex: 1,
    width: '100%',
  },

  itemTitle: {
    color: isTablet ? '#474747' : COLORS.BLACK_80,
    marginTop: isTablet ? 0 : 3,
    marginBottom: isTablet ? mvs(4) : 10,
  },

  itemDate: {
    color: COLORS.BLACK_50,
    height: isTablet ? undefined : 16,
    marginTop: isTablet ? mvs(18) : 10,
    marginBottom: isTablet ? mvs(2) : 10,
  },

  itemPicture: {
    aspectRatio: 9 / 5,
  },

  itemVideo: {
    alignSelf: 'center',
    aspectRatio: 16 / 9,
    // height: 200,
    width: '100%',
  },

  showOnlineButton: {
    marginTop: isTablet ? mvs(20) : 10,
    marginBottom: isTablet ? mvs(10) : mvs(10),
    alignSelf: isTablet ? 'center' : undefined,
    paddingHorizontal: isTablet ? mvs(30) : undefined,
  },

  showOnlineButtonText: {
    fontSize: isTablet ? mvs(12) : mvs(20, 0.35),
  },

  shareButton: {
    paddingLeft: 2,
    paddingBottom: 2,
  },

  tabletShareButtonContainer: {
    right: mvs(42),
    top: IS_IOS ? mvs(-18) : mvs(-17),
  },

  tabletShareButton: {
    width: 46,
    height: 46,
    paddingBottom: IS_IOS ? 4 : undefined,
    paddingRight: IS_IOS ? undefined : 2,
  },

  closeButton: {
    top: -24,
    right: isTablet ? 0 : 24,
  },

  sliderContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    borderBottomStartRadius: 20,
    borderBottomEndRadius: 20,
    flexDirection: 'row',
    backgroundColor: '#474747',
    justifyContent: 'center',
    alignContent: 'center',
  },

  slider: {
    flex: 1,
    flexShrink: 1,
    height: mvs(35),
  },

  sliderText: {
    color: '#fff',
    padding: isTablet ? mvs(2) : 2,
    alignSelf: 'center',
    paddingRight: isTablet ? mvs(10) : 10,
  },

  playerButton: {
    alignSelf: 'center',
    paddingLeft: isTablet ? mvs(5) : 5,
  },
});
