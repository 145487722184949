// LiveFeedScreen.tsx

import { DrawerActions } from '@react-navigation/native';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';
import { mvs } from 'react-native-size-matters';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { LiveFeedPost } from '../components/Livefeed/LiveFeedPost';
import { MenuIcon } from '../components/MenuIcon';
import { ScreenLayout } from '../components/ScreenLayout';
import { TabName } from '../components/TabName';
import { DataState, IS_WEB, REDUCED_HORIZONTAL_MARGIN } from '../constants';
import { selectAppConfig, selectHasMargin } from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import { fetchLiveFeed as fetchLiveFeedAction } from '../features/liveFeed/store/actions';
import {
  selectLiveFeed,
  selectLiveFeedState,
} from '../features/liveFeed/store/selectors';
import { showAlert } from '../features/modal/store/slice';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import i18n from '../localization/i18n';
import { LiveFeedPostModel, LiveFeedTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';

export default function LiveFeedScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'LiveFeed'>) {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const liveFeed = useAppSelector(selectLiveFeed);
  const liveFeedState = useAppSelector(selectLiveFeedState);
  const liveFeedListRef = useRef<FlatList<LiveFeedPostModel>>(null);
  const [refreshing, setRefreshing] = useState(false);
  const tabOptions = route.params.options as LiveFeedTabModel;
  const bearerToken = useAppSelector(selectBearerToken);

  // Applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const bodyHorizontalPadding = hasMargin ? mvs(32) : REDUCED_HORIZONTAL_MARGIN;
  const maxWidth = useMemo(() => {
    const withMarginMaxWidth = IS_WEB ? 300 : mvs(450);
    return hasMargin ? withMarginMaxWidth : '100%';
  }, [hasMargin]);

  // Dynamic font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));

  const fetchLiveFeed = useCallback(() => {
    dispatch(fetchLiveFeedAction(appConfig, bearerToken));
  }, [appConfig, bearerToken, dispatch]);

  useEffect(() => {
    fetchLiveFeed();
  }, [fetchLiveFeed]);

  useEffect(() => {
    if (liveFeedState !== DataState.LOADING) {
      setRefreshing(false);
    }

    if (liveFeedState === DataState.ERROR) {
      dispatch(
        showAlert({
          key: 'fetch-livefeed-error',
          type: 'warning',
          text: i18n.t('alert.noConnection'),
          closable: false,
          autoCloseAfterMs: 2000,
        }),
      );
    }
  }, [dispatch, liveFeedState]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchLiveFeed();
  }, [fetchLiveFeed]);

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const renderLiveFeedPost = useCallback(
    ({ item: liveFeedPost }: { item: LiveFeedPostModel }) => (
      <LiveFeedPost liveFeedPost={liveFeedPost} />
    ),
    [],
  );

  const liveFeedListKeyExtractor = useCallback(
    (item: LiveFeedPostModel) => `${item.idlivefeeds}`,
    [],
  );

  return (
    <ScreenLayout
      headerContents={
        <>
          <MenuIcon
            color={tabOptions.headerTextColor}
            toggleLeftDrawer={toggleLeftDrawer}
          />
          <TabName
            title={tabOptions.titre}
            color={tabOptions.headerTextColor}
            titleFontSize={titleFontSize}
            titleLineHeight={titleLineHeight}
          />
        </>
      }
    >
      <FlatList
        ref={liveFeedListRef}
        data={liveFeed}
        renderItem={renderLiveFeedPost}
        keyExtractor={liveFeedListKeyExtractor}
        contentContainerStyle={[
          styles.body,
          {
            paddingHorizontal: bodyHorizontalPadding,
            maxWidth: maxWidth,
          },
        ]}
        showsVerticalScrollIndicator={false}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      />
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: mvs(18),
    width: '100%',
    alignSelf: 'center',
  },
});
