import { Button } from '@rneui/themed';
import {
  Linking,
  Modal,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { mvs } from 'react-native-size-matters';

import { useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { COLORS , isTablet, WINDOW_WIDTH } from '../constants';
import {
  selectContactEmail,
  selectContactPhone,
  selectContactSMS,
  selectDivRadius,
  selectFontFamily,
} from '../features/appconfig/store/selectors';
import { getFontFamily } from '../helpers/fontMapper';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import i18n from '../localization/i18n';

export interface ContactModalScreenProps {
  visible: boolean;
  onRequestClose: (visible: boolean) => void;
}

export default function ContactModal({
  visible = false,
  onRequestClose,
}: ContactModalScreenProps) {
  const email = useAppSelector(selectContactEmail);
  const phone = useAppSelector(selectContactPhone);
  const sms = useAppSelector(selectContactSMS);
  const fontFamily = useAppSelector(selectFontFamily);

  const dynamicTitleStyle = [
    styles.button,
    {
      fontFamily: getFontFamily(fontFamily, 'SemiBold'),
      fontSize: useAdjustedFontSize(isTablet ? mvs(16) : 16),
    },
  ];
  const titleFontSize = useAdjustedFontSize(isTablet ? mvs(20) : 20);
  const divRadius = useAppSelector(selectDivRadius);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent={true}
      onRequestClose={() => onRequestClose(false)}
    >
      <TouchableWithoutFeedback onPress={() => onRequestClose(false)}>
        <SafeAreaView edges={['bottom']} style={styles.modal}>
          <TouchableWithoutFeedback>
            <View style={[styles.modalBody, { borderRadius: mvs(divRadius) }]}>
              <CustomText
                style={[styles.title, { fontSize: titleFontSize }]}
                weight="SemiBold"
              >
                {i18n.t('contactModal.title')}
              </CustomText>

              {phone && (
                <Button
                  type={'clear'}
                  buttonStyle={styles.optionButton}
                  titleStyle={dynamicTitleStyle}
                  TouchableComponent={TouchableOpacity}
                  title={i18n.t('contactModal.phone')}
                  onPress={() => {
                    Linking.openURL(`tel:${phone}`);
                    onRequestClose(false);
                  }}
                />
              )}
              {sms && (
                <Button
                  type={'clear'}
                  buttonStyle={styles.optionButton}
                  titleStyle={dynamicTitleStyle}
                  TouchableComponent={TouchableOpacity}
                  title={'SMS'}
                  onPress={() => {
                    Linking.openURL(`sms:${sms}`);
                    onRequestClose(false);
                  }}
                />
              )}
              {email && (
                <Button
                  type={'clear'}
                  buttonStyle={[styles.optionButton]}
                  titleStyle={dynamicTitleStyle}
                  TouchableComponent={TouchableOpacity}
                  title={'Email'}
                  onPress={() => {
                    Linking.openURL(`mailto:${email}`);
                    onRequestClose(false);
                  }}
                />
              )}
            </View>
          </TouchableWithoutFeedback>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalBody: {
    backgroundColor: '#F2F2F6',
    width: isTablet ? mvs(274) : WINDOW_WIDTH - 40,
  },

  title: {
    alignSelf: 'center',
    marginBottom: mvs(15),
    marginTop: mvs(10),
  },

  button: {
    color: COLORS.BLACK,
  },

  optionButton: {
    borderTopWidth: 1,
    borderTopColor: '#CCCCCC',
    padding: mvs(10),
  },
});
