import { useCallback, useEffect, useRef } from 'react';
import { Animated, StyleSheet, View, ViewStyle } from 'react-native';
import { mvs } from 'react-native-size-matters';
import { useDispatch } from 'react-redux';

import { CustomIcon } from './CustomIcon';
import CustomText from './CustomText';
import { useAppSelector } from '../app/hooks';
import { COLORS, IS_IOS , isTablet, TEXT_STYLES } from '../constants';
import { hideAlert } from '../features/modal/store/actions';
import { selectAlertData } from '../features/modal/store/selectors';
import { AlertData } from '../features/modal/store/slice';
import useKeyboardHeight from '../hooks/useKeyboardHeight';
import { useShadowStyles } from '../hooks/useShadowStyles';

const POSITIONS = {
  show: -120,
  hide: 0,
};

function alertStyleByType(type: AlertData['type']): ViewStyle {
  switch (type) {
    case 'info':
      return styles.lineInfo;
    case 'warning':
      return styles.lineWarning;
    case 'danger':
      return styles.lineDanger;
  }
}

export const AlertComponent = () => {
  const position = useRef(new Animated.Value(POSITIONS.hide)).current;
  const dispatch = useDispatch();
  const alert = useAppSelector(selectAlertData);
  const keyboardHeight = useKeyboardHeight();
  const shadowStyles = useShadowStyles();

  const hide = useCallback(() => {
    if (!alert) {
      return;
    }
    Animated.timing(position, {
      toValue: POSITIONS.hide,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      dispatch(hideAlert(alert.key));
    });
  }, [dispatch, position, alert]);

  useEffect(() => {
    if (alert) {
      Animated.timing(position, {
        toValue: POSITIONS.show - (IS_IOS ? keyboardHeight : 0),
        duration: 200,
        useNativeDriver: true,
      }).start();

      if (alert.autoCloseAfterMs) {
        const timeout = setTimeout(hide, alert.autoCloseAfterMs);
        return () => clearTimeout(timeout);
      }
    }
  }, [position, alert, hide, keyboardHeight]);

  if (!alert) {
    return null;
  }

  return (
    <Animated.View
      style={[styles.container, shadowStyles, { transform: [{ translateY: position }] }]}
    >
      <View style={styles.content}>
        <View style={[styles.line, alertStyleByType(alert.type)]} />
        <View style={styles.body}>
          <CustomText style={styles.text} weight="Regular">
            {alert.text}
          </CustomText>
          {alert.closable && (
            <View style={styles.buttonContainer}>
              <CustomIcon
                name="icon_close"
                color={COLORS.SHARK}
                size={24}
                onPress={hide}
              />
            </View>
          )}
        </View>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: mvs(10),
    position: 'absolute',
    bottom: isTablet ? 0 : mvs(-64),
    left: mvs(9),
    right: mvs(9),
    zIndex: 2,
    overflow: 'hidden',
  },
  content: {
    backgroundColor: COLORS.WHITE,
    alignItems: 'center',
    borderRadius: mvs(8),
    alignSelf: 'center',
    flexDirection: 'row',
    height: '100%',
    overflow: 'hidden',
  },
  line: {
    width: 4,
    height: '100%',
  },
  lineInfo: {
    backgroundColor: COLORS.GREEN_SUCCESS,
  },
  lineWarning: {
    backgroundColor: COLORS.SUNSET_ORANGE_2,
  },
  lineDanger: {
    backgroundColor: COLORS.RED_ORANGE,
  },
  body: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: mvs(8),
  },
  text: {
    flexShrink: 1,
    color: COLORS.COCOA_BROWN,
    margin: mvs(12),
    ...TEXT_STYLES.regular14x21,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
