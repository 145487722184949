/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import { Ref, useRef } from 'react';
import { ColorSchemeName, LogBox } from 'react-native';

import { AppNavigator } from './AppNavigator';
import { AlertComponent } from '../components/AlertComponent';
import { KeyboardAvoiding } from '../components/KeyboardAvoiding';
import { IS_WEB , THEME } from '../constants';

export default function Navigation({
  colorScheme,
  navigationRef,
}: {
  colorScheme: ColorSchemeName;
  navigationRef?: Ref<NavigationContainerRef<ReactNavigation.RootParamList>>;
}) {
  LogBox.ignoreAllLogs(true);
  const routeNameRef = useRef();

  return (
    <NavigationContainer
      // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme} TODO Handle dark theme
      theme={colorScheme === 'dark' ? THEME : THEME}
      ref={navigationRef}
      onReady={() => {
        if (IS_WEB) {
          routeNameRef.current = navigationRef.getCurrentRoute().name;
        }
      }}
      onStateChange={async () => {
        if (IS_WEB) {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute().name;
          const trackScreenView = () => {
            window.parent.postMessage(
              { type: 'tab', index: currentRouteName.replace('tab', '') },
              '*',
            );
          };

          if (previousRouteName !== currentRouteName) {
            routeNameRef.current = currentRouteName;
            await trackScreenView();
          }
        }
      }}
    >
      <KeyboardAvoiding>
        <AppNavigator />
        <AlertComponent />
      </KeyboardAvoiding>
    </NavigationContainer>
  );
}
