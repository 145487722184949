import { DrawerActions } from '@react-navigation/native';
import { useCallback } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';

import { useAppSelector } from '../app/hooks';
import { AudioDedication } from '../components/AudioDedication';
import CustomText from '../components/CustomText';
import { MenuIcon } from '../components/MenuIcon';
import { ScreenLayout } from '../components/ScreenLayout';
import { TabName } from '../components/TabName';
import { REDUCED_HORIZONTAL_MARGIN } from '../constants';
import { selectDivRadius, selectHasMargin } from '../features/appconfig/store/selectors';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import i18n from '../localization/i18n';
import { AudioDedicationTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';

export default function AudioDedicationScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'AudioDedication'>) {
  const tabOptions = route.params.options as AudioDedicationTabModel;
  const windowWidth = useWindowDimensions().width;
  const divRadius = useAppSelector(selectDivRadius);
  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const bodyHorizontalMargin = hasMargin ? 10 : REDUCED_HORIZONTAL_MARGIN + 2;
  const textHorizonttalMargin = hasMargin ? mvs(22) : REDUCED_HORIZONTAL_MARGIN;

  // Dynamic font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));
  const tipTextFontSize = useAdjustedFontSize(mvs(12));
  const customMessageFontSize = useAdjustedFontSize(mvs(16));

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  return (
    <ScreenLayout
      headerContents={
        <>
          <MenuIcon
            color={tabOptions.headerTextColor}
            toggleLeftDrawer={toggleLeftDrawer}
          />
          <TabName
            title={tabOptions.titre}
            color={tabOptions.headerTextColor}
            titleFontSize={titleFontSize}
            titleLineHeight={titleLineHeight}
          />
        </>
      }
    >
      <View
        style={[
          styles.body,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            width: 'auto',
            marginHorizontal: bodyHorizontalMargin,
            justifyContent: 'space-between',
          },
        ]}
      >
        {tabOptions.welcomeMessage && (
          <View
            style={[
              styles.customMessageContainer,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                borderRadius: mvs(divRadius),
                marginHorizontal: textHorizonttalMargin,
                opacity: tabOptions.welcomeMessage ? 1 : 0,
                display: 'flex',
              },
            ]}
          >
            <CustomText style={{ fontSize: customMessageFontSize }}>
              {tabOptions.welcomeMessage}
            </CustomText>
          </View>
        )}

        <AudioDedication
          isRecording={false}
          isRecorded={false}
          isSent={false}
          toggleRecording={() => {}}
          toggleSent={() => {}}
          togglePlayPause={() => {}}
          isPlaying={false}
          countdown={0}
          duration={0}
          audioURI={null}
          isActive={true}
          width={windowWidth}
        />
        <SafeAreaView edges={['bottom']}>
          <View
            style={[
              styles.tip,
              {
                borderRadius: mvs(divRadius),
                marginHorizontal: textHorizonttalMargin,
              },
            ]}
          >
            <CustomText
              style={[styles.tipText, { fontSize: tipTextFontSize }]}
              numberOfLines={2}
              weight={'Regular'}
            >
              {i18n.t('audioDedication.tip')}
            </CustomText>
          </View>
        </SafeAreaView>
      </View>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    alignSelf: 'auto',
  },
  tip: {
    backgroundColor: '#fff',
    paddingHorizontal: mvs(22),
    paddingVertical: mvs(8),
    marginBottom: mvs(27),
    marginTop: mvs(14),
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    alignSelf: 'center',
  },
  tipText: {
    color: '#474747',
  },
  customMessageContainer: {
    backgroundColor: '#fff',
    paddingHorizontal: mvs(24),
    paddingVertical: mvs(12),
    marginTop: mvs(14),
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    alignSelf: 'center',
  },
});
