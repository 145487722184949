import { ImageURISource } from 'react-native';

import { AppConfigSliceState } from './slice';
import { getUrlFromImageName } from '../../../api/images';
import { RootState } from '../../../app/reducer';
import { DataState, DEFAULT_COVER , IS_WEB, isLongScreenDevice } from '../../../constants';
import { RUN_AS_TEST } from '../../../constants/Test';
import {
  AppConfigModel,
  AppConfigTabs,
  ButtonStyle,
  FontFamily,
  FontSizeLevel,
  GradientDirection,
  RadioTabModel,
  TextAlign,
} from '../../../models';

export const selectAppConfig = ({ appConfigReducer }: RootState): AppConfigModel =>
  appConfigReducer.appConfig.data;

export const selectAppConfigState = ({ appConfigReducer }: RootState): DataState =>
  appConfigReducer.appConfig.state;

export const selectLastUpdate = ({
  appConfigReducer,
}: RootState): AppConfigSliceState['lastUpdate'] => appConfigReducer.lastUpdate;

export const selectStatus = ({
  appConfigReducer,
}: RootState): AppConfigSliceState['status'] => appConfigReducer.status;

export const selectRadioTab = ({ appConfigReducer }: RootState): RadioTabModel | null =>
  appConfigReducer.appConfig.data === null
    ? null
    : appConfigReducer.appConfig.data.onglets[0];

export const selectBackground = ({
  appConfigReducer,
}: RootState): number | ImageURISource | null => {
  const appConfig = appConfigReducer.appConfig.data;
  const radioTab = appConfig.onglets[0];

  if (radioTab.showBlurredCover !== 'NO') {
    if (radioTab.pochettedefaut) {
      return {
        uri: getUrlFromImageName(appConfig, radioTab.pochettedefaut),
      };
    } else {
      return DEFAULT_COVER;
    }
  } else if (radioTab.backgroundappfield || radioTab.backgroundiphoneXappfield) {
    if (isLongScreenDevice) {
      if (radioTab.backgroundiphoneXappfield) {
        return {
          uri: getUrlFromImageName(appConfig, radioTab.backgroundiphoneXappfield),
        };
      } else {
        return {
          uri: getUrlFromImageName(appConfig, radioTab.backgroundappfield),
        };
      }
    } else {
      if (radioTab.backgroundappfield) {
        return {
          uri: getUrlFromImageName(appConfig, radioTab.backgroundappfield),
        };
      } else {
        return {
          uri: getUrlFromImageName(appConfig, radioTab.backgroundiphoneXappfield),
        };
      }
    }
  } else {
    return null;
  }
};

export const selectCover = ({ appConfigReducer }: RootState): string | null => {
  const appConfig = appConfigReducer.appConfig.data;
  const radioTab = appConfig.onglets[0];

  if (radioTab.pochettedefaut) {
    return getUrlFromImageName(appConfig, radioTab.pochettedefaut);
  }

  return null;
};

export const selectDisplayContactButton = ({ appConfigReducer }: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].callOption === 'YES' ||
  appConfigReducer.appConfig.data.onglets[0].emailOption === 'YES' ||
  appConfigReducer.appConfig.data.onglets[0].smsOption === 'YES';

export const selectContactEmail = ({ appConfigReducer }: RootState): string | null =>
  appConfigReducer.appConfig.data.onglets[0].emailOption === 'YES'
    ? appConfigReducer.appConfig.data.onglets[0].emailAddress
    : null;

export const selectContactSMS = ({ appConfigReducer }: RootState): string | null =>
  appConfigReducer.appConfig.data.onglets[0].smsOption === 'YES'
    ? appConfigReducer.appConfig.data.onglets[0].smsNumber
    : null;

export const selectContactPhone = ({ appConfigReducer }: RootState): string | null =>
  appConfigReducer.appConfig.data.onglets[0].callOption === 'YES'
    ? appConfigReducer.appConfig.data.onglets[0].phoneNumber
    : null;

export const selectDisplayShareButton = ({ appConfigReducer }: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].partage === 'YES';

export const selectDisplayBuyButton = ({ appConfigReducer }: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].achatOption === 'YES';

export const selectTabs = ({ appConfigReducer }: RootState): AppConfigTabs =>
  appConfigReducer.appConfig.data.onglets;

export const selectMenuColor = ({ appConfigReducer }: RootState): string =>
  appConfigReducer.appConfig.data.menuColor;

export const selectMenuBackgroundColor = ({ appConfigReducer }: RootState): string =>
  appConfigReducer.appConfig.data.menuBackgroundColor;

export const selectMenuBackgroundImage = ({ appConfigReducer }: RootState): string =>
  appConfigReducer.appConfig.data.themeMenu;

export const selectMenuLogo = ({ appConfigReducer }: RootState): string =>
  appConfigReducer.appConfig.data.onglets[0].logoappfield;

export const selectMainColor = ({ appConfigReducer }: RootState): string =>
  appConfigReducer.appConfig.data.onglets[0].mainColor;

export const selectAlwaysUseDefaultCoverOption = ({
  appConfigReducer,
}: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].alwaysDefaultCover === 'YES';

export const selectGradientBackgroundDirection = ({
  appConfigReducer,
}: RootState): GradientDirection =>
  appConfigReducer.appConfig.data.onglets[0].gradientDirection;

export const selectSleepTimer = ({ appConfigReducer }: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].sleeptimerOption === 'YES';

export const selectIconStyle = ({ appConfigReducer }: RootState): 'solid' | 'regular' =>
  appConfigReducer.appConfig.data.onglets[0].iconStyle;

export const selectFontFamily = ({ appConfigReducer }: RootState): FontFamily =>
  appConfigReducer.appConfig.data.onglets[0].fontFamily || 'Quicksand';

export const selectFontSize = ({ appConfigReducer }: RootState): FontSizeLevel =>
  appConfigReducer.appConfig.data.onglets[0].fontSize;

export const selectDivRadius = ({ appConfigReducer }: RootState): number =>
  appConfigReducer.appConfig.data.onglets[0].divRadius;

export const selectDivShadow = ({ appConfigReducer }: RootState): number =>
  appConfigReducer.appConfig.data.onglets[0].divShadow;

export const selectTextAlign = ({ appConfigReducer }: RootState): TextAlign =>
  appConfigReducer.appConfig.data.onglets[0].textAlign;

export const selectButtonStyle = ({ appConfigReducer }: RootState): ButtonStyle =>
  appConfigReducer.appConfig.data.onglets[0].buttonStyle;

export const selectHasMargin = ({ appConfigReducer }: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].divWithMargin === 'YES';

export const showCoverBorder = ({ appConfigReducer }: RootState): boolean =>
  appConfigReducer.appConfig.data.onglets[0].showCoverBorder === 'YES';

export const coverBorderColor = ({ appConfigReducer }: RootState): string =>
  appConfigReducer.appConfig.data.onglets[0].coverBorderColor;

export const selectShowBanner = ({ appConfigReducer }: RootState): boolean =>
  !IS_WEB &&
  !RUN_AS_TEST &&
  appConfigReducer.appConfig.data !== null &&
  appConfigReducer.appConfig.data.pubOption === 'YES' &&
  appConfigReducer.appConfig.data.pubProvider === 'admob' &&
  appConfigReducer.appConfig.data.admobBannerId;
