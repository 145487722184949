import {
  faBell as faBellRegular,
  faCartShopping as faCartShoppingRegular,
  faCheck as faCheckRegular,
  faEnvelope as faEnvelopeRegular,
  faMicrophone as faMicrophoneRegular,
  faMoon as faMoonRegular,
  faNavicon as faNaviconRegular,
  faPaperPlane as faPaperPlaneRegular,
  faPause as faPauseRegular,
  faPenToSquare as faPenToSquareRegular,
  faPlay as faPlayRegular,
  faPlus as faPlusRegular,
  faRadio as faRadioRegular,
  faShareFromSquare as faShareFromSquareRegular,
  faShareNodes as faShareNodesRegular,
  faStop as faStopRegular,
  faThumbsDown as faThumbsDownRegular,
  faThumbsUp as faThumbsUpRegular,
  faVolumeHigh as faVolumeHighRegular,
  faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBell,
  faCartShopping,
  faCheck,
  faEnvelope,
  faMicrophone,
  faMoon,
  faNavicon,
  faPaperPlane,
  faPause,
  faPenToSquare,
  faPlay,
  faPlus,
  faRadio,
  faShareFromSquare,
  faShareNodes,
  faStop,
  faThumbsDown,
  faThumbsUp,
  faVolumeHigh,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';
import { mvs } from 'react-native-size-matters';

import { SoundQualityIcon } from './SoundQualityIcon';
import { useAppSelector } from '../app/hooks';
import { IS_IOS, IS_WEB } from '../constants';
import { selectIconStyle } from '../features/appconfig/store/selectors';

export type CustomIconName =
  | 'icon_play_with_offset'
  | 'icon_pause'
  | 'icon_volume'
  | 'icon_muted'
  | 'icon_vote_like'
  | 'icon_vote_dislike'
  | 'icon_contact'
  | 'icon_share_player'
  | 'icon_share_with_offset'
  | 'icon_shop'
  | 'icon_hd'
  | 'icon_sd'
  | 'icon_switch_radio'
  | 'icon_close'
  | 'icon_menu'
  | 'icon_edit_with_offset'
  | 'icon_play_video_with_offset'
  | 'icon_record'
  | 'icon_stop'
  | 'icon_listening'
  | 'icon_abort'
  | 'icon_send'
  | 'icon_check'
  | 'icon_about_radio'
  | 'icon_about_website'
  | 'icon_about_mobile'
  | 'icon_about_player'
  | 'icon_plus_with_offset'
  | 'icon_alarm'
  | 'icon-moon-outline'
  | 'icon-moon-sharp';

export type CustomIconProps = {
  name: CustomIconName;
  size?: number;
  color?: string;
  style?: ViewStyle;
  onPress?: TouchableOpacityProps['onPress'];
  hitSlop?: TouchableOpacityProps['hitSlop'];
  disabled?: boolean;
};

export const CustomIcon = (props: CustomIconProps) => {
  const { onPress, style, disabled, hitSlop, ...otherProps } = props;
  const iconStyle = useAppSelector(selectIconStyle);

  const icon = iconMap(otherProps, iconStyle);

  if (!onPress) {
    return icon;
  }

  return (
    <TouchableOpacity
      style={style as ViewStyle}
      onPress={onPress}
      hitSlop={hitSlop ?? { top: 5, bottom: 5, left: 5, right: 5 }}
      disabled={disabled}
    >
      {icon}
    </TouchableOpacity>
  );
};

const iconMap = (otherProps: CustomIconProps, iconStyle: 'solid' | 'regular') => {
  const isSolid = iconStyle === 'solid';

  switch (otherProps.name) {
    case 'icon_play_with_offset':
      return (
        <FontAwesomeIcon
          style={{ marginLeft: mvs(3) }}
          {...otherProps}
          icon={isSolid ? faPlay : faPlayRegular}
        />
      );
    case 'icon_pause':
      return (
        <FontAwesomeIcon {...otherProps} icon={isSolid ? faPause : faPauseRegular} />
      );
    case 'icon_vote_like':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faThumbsUp : faThumbsUpRegular}
        />
      );
    case 'icon_vote_dislike':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faThumbsDown : faThumbsDownRegular}
        />
      );
    case 'icon_contact':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faEnvelope : faEnvelopeRegular}
        />
      );
    case 'icon_share_player':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faShareNodes : faShareNodesRegular}
        />
      );
    case 'icon_share_with_offset':
      return IS_IOS ? (
        <FontAwesomeIcon
          style={{ marginLeft: mvs(3) }}
          {...otherProps}
          icon={isSolid ? faShareFromSquare : faShareFromSquareRegular}
        />
      ) : (
        <FontAwesomeIcon
          style={{ marginRight: mvs(1) }}
          {...otherProps}
          icon={isSolid ? faShareNodes : faShareNodesRegular}
        />
      );
    case 'icon_shop':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faCartShopping : faCartShoppingRegular}
        />
      );
    case 'icon_plus_with_offset':
      return (
        <FontAwesomeIcon
          {...otherProps}
          style={{ marginRight: 2 }}
          icon={isSolid ? faPlus : faPlusRegular}
        />
      );
    case 'icon_alarm':
      return <FontAwesomeIcon {...otherProps} icon={isSolid ? faBell : faBellRegular} />;
    case 'icon_hd':
      return <SoundQualityIcon isHd={true} />;
    case 'icon_sd':
      return <SoundQualityIcon isHd={false} />;
    case 'icon_switch_radio':
      return (
        <FontAwesomeIcon {...otherProps} icon={isSolid ? faRadio : faRadioRegular} />
      );
    case 'icon_close':
      return (
        <FontAwesomeIcon {...otherProps} icon={isSolid ? faXmark : faXmarkRegular} />
      );
    case 'icon_menu':
      return (
        <FontAwesomeIcon {...otherProps} icon={isSolid ? faNavicon : faNaviconRegular} />
      );
    case 'icon_edit_with_offset':
      return (
        <FontAwesomeIcon
          style={{ marginLeft: mvs(3), marginBottom: IS_IOS ? mvs(2) : 0 }}
          {...otherProps}
          icon={isSolid ? faPenToSquare : faPenToSquareRegular}
        />
      );
    case 'icon_play_video_with_offset':
      return (
        <FontAwesomeIcon
          style={{ marginLeft: mvs(3) }}
          {...otherProps}
          icon={isSolid ? faPlay : faPlayRegular}
        />
      );
    case 'icon_record':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faMicrophone : faMicrophoneRegular}
        />
      );
    case 'icon_stop':
      return <FontAwesomeIcon {...otherProps} icon={isSolid ? faStop : faStopRegular} />;
    case 'icon_listening':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faVolumeHigh : faVolumeHighRegular}
        />
      );
    case 'icon_abort':
      return (
        <FontAwesomeIcon {...otherProps} icon={isSolid ? faXmark : faXmarkRegular} />
      );
    case 'icon_send':
      return (
        <FontAwesomeIcon
          {...otherProps}
          icon={isSolid ? faPaperPlane : faPaperPlaneRegular}
        />
      );
    case 'icon_check':
      return (
        <FontAwesomeIcon {...otherProps} icon={isSolid ? faCheck : faCheckRegular} />
      );
    case 'icon_about_radio':
      return (
        <Image
          style={IS_WEB ? styles.aboutRadioIcon : undefined}
          source={require('../assets/icons/about-radio-icon.png')}
        />
      );
    case 'icon_about_website':
      return (
        <Image
          style={IS_WEB ? styles.aboutWebsiteIcon : undefined}
          source={require('../assets/icons/about-website-icon.png')}
        />
      );
    case 'icon_about_mobile':
      return (
        <Image
          style={IS_WEB ? styles.aboutMobileIcon : undefined}
          source={require('../assets/icons/about-mobile-icon.png')}
        />
      );
    case 'icon_about_player':
      return (
        <Image
          style={IS_WEB ? styles.aboutPlayerIcon : undefined}
          source={require('../assets/icons/about-player-icon.png')}
        />
      );
    case 'icon-moon-outline':
      return <FontAwesomeIcon {...otherProps} icon={isSolid ? faMoon : faMoonRegular} />;
    case 'icon-moon-sharp':
      return <FontAwesomeIcon {...otherProps} icon={isSolid ? faMoon : faMoonRegular} />;
  }
  return null;
};

const styles = StyleSheet.create({
  aboutRadioIcon: {
    width: 25,
    height: 25,
  },
  aboutWebsiteIcon: {
    width: 25,
    height: 23,
  },
  aboutMobileIcon: {
    width: 16,
    height: 25,
  },
  aboutPlayerIcon: {
    width: 20,
    height: 25,
  },
});
