import { StyleSheet } from 'react-native';
import { G, Path, Svg } from 'react-native-svg';

import { COLORS } from '../constants';


interface Props {
  isHd: boolean;
}

export const SoundQualityIcon = ({ isHd }: Props) => {
  const scale = 1.3; // Change this value to scale the icon
  const width = 20 * scale;
  const height = 16 * scale;
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles.icon}
    >
      <G transform={`scale(${scale})`}>
        {isHd ? (
          <Path
            d="M4 11h1.5V9h2v2H9V5H7.5v2.5h-2V5H4v6zm7 0h4a.968.968 0 00.713-.288A.964.964 0 0016 10V6a.968.968 0 00-.288-.713A.964.964 0 0015 5h-4v6zm1.5-1.5v-3h2v3h-2zM2 16c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 010 14V2C0 1.45.196.979.588.587A1.922 1.922 0 012 0h16c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v12c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0118 16H2z"
            fill={COLORS.WHITE}
          />
        ) : (
          <Path
            d="M2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H18C18.55 1.69779e-06 19.021 0.196001 19.413 0.588001C19.805 0.980001 20.0007 1.45067 20 2V14C20 14.55 19.804 15.021 19.412 15.413C19.02 15.805 18.5493 16.0007 18 16H2ZM5 11H8C8.28334 11 8.521 10.904 8.713 10.712C8.905 10.52 9.00067 10.2827 9 10V8.5C9 8.21667 8.904 7.979 8.712 7.787C8.52 7.595 8.28267 7.49934 8 7.5H5.5V6.5H7.5V7H9V6C9 5.71667 8.904 5.479 8.712 5.287C8.52 5.095 8.28267 4.99934 8 5H5C4.71667 5 4.479 5.096 4.287 5.288C4.095 5.48 3.99934 5.71734 4 6V7.5C4 7.78334 4.096 8.021 4.288 8.213C4.48 8.405 4.71734 8.50067 5 8.5H7.5V9.5H5.5V9H4V10C4 10.2833 4.096 10.521 4.288 10.713C4.48 10.905 4.71734 11.0007 5 11ZM11 11H15C15.2833 11 15.521 10.904 15.713 10.712C15.905 10.52 16.0007 10.2827 16 10V6C16 5.71667 15.904 5.479 15.712 5.287C15.52 5.095 15.2827 4.99934 15 5H11V11ZM12.5 9.5V6.5H14.5V9.5H12.5Z"
            fill={COLORS.WHITE}
          />
        )}
      </G>
    </Svg>
  );
};

const styles = StyleSheet.create({
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 2,
  },
});
