import { Avatar, Button } from '@rneui/themed';
import React, { ReactNode, useState } from 'react';
import { Linking, Share, StyleSheet, View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { useAppSelector } from '../../app/hooks';
import { IS_ANDROID, IS_WEB, isTablet } from '../../constants';
import {
  selectAppConfig,
  selectDivRadius,
  selectFontFamily,
  selectHasMargin,
  selectMainColor,
  selectTextAlign,
} from '../../features/appconfig/store/selectors';
import { getFontFamily } from '../../helpers/fontMapper';
import { getSecondaryColor } from '../../helpers/getSecondaryColor';
import { useAdjustedFontSize } from '../../hooks/useAdjustedFontSize';
import { useShadowStyles } from '../../hooks/useShadowStyles';
import { dateFromSQLUTC, formatFromNow } from '../../localization/datetime';
import i18n from '../../localization/i18n';
import { LiveFeedPostModel, TextAlign } from '../../models';
import { CustomIcon } from '../CustomIcon';
import CustomText from '../CustomText';

interface LiveFeedPostWrapperProps {
  liveFeedPost: LiveFeedPostModel;
  children: ReactNode;
  bottomActionButton?: React.ReactElement | null;
}

export const LiveFeedPostWrapper = ({
  liveFeedPost,
  children,
  bottomActionButton,
}: LiveFeedPostWrapperProps) => {
  const author = liveFeedPost.auteur_nom?.trim() ?? '';
  const avatar = liveFeedPost.auteur_photo;
  const formattedDate = liveFeedPost.date_publication
    ? formatFromNow(dateFromSQLUTC(liveFeedPost.date_publication))
    : '';
  const picture = liveFeedPost.photo;
  const youtubeUrl = liveFeedPost.urlyoutube;
  const link = liveFeedPost.lien;
  const shareMessage = liveFeedPost.message;
  const appConfig = useAppSelector(selectAppConfig);
  const shareUrl = link ?? youtubeUrl ?? picture ?? appConfig.urlSiteRadio ?? undefined;
  const mainColor = useAppSelector(selectMainColor);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  // Applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const avatarLeftOffset = hasMargin ? mvs(-13) : 0;

  // Dynamic font sizes
  const liveFeedPostAuthorFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const liveFeedPostDateFontSize = useAdjustedFontSize(mvs(14, 0.35));
  const liveFeedPostButtonLinkTitleFontSize = useAdjustedFontSize(mvs(20, 0.35));

  const [shadow, setShadow] = useState(true);

  const dynamicHeaderStyles = (align: TextAlign): ViewStyle => {
    switch (align) {
      case 'center':
        return { justifyContent: 'center' };
      case 'right':
        return { justifyContent: 'flex-end' };
      default:
        return {};
    }
  };

  return (
    <>
      <View style={[styles.liveFeedPost, { borderRadius: mvs(divRadius) }]}>
        <View style={[styles.liveFeedPostHeader, dynamicHeaderStyles(textAlign)]}>
          <CustomText
            numberOfLines={1}
            style={[styles.liveFeedPostAuthor, { fontSize: liveFeedPostAuthorFontSize }]}
            weight="SemiBold"
          >
            {author}
          </CustomText>
          <CustomText
            numberOfLines={1}
            style={[styles.liveFeedPostDate, { fontSize: liveFeedPostDateFontSize }]}
            weight="Regular"
          >
            - {formattedDate}
          </CustomText>
        </View>
        <View style={styles.liveFeedPostContents}>{children}</View>
      </View>
      <Avatar
        source={avatar ? { uri: avatar } : {}}
        rounded
        size={mvs(57)}
        containerStyle={[
          styles.liveFeedPostAvatarContainer,
          shadowStyles,
          { left: avatarLeftOffset },
        ]}
      />
      <View style={styles.liveFeedPostBottomActions}>
        {bottomActionButton ? (
          bottomActionButton
        ) : link ? (
          <Button
            containerStyle={[
              styles.liveFeedPostButtonLinkContainer,
              { borderRadius: mvs(divRadius) },
              shadowStyles,
              shadow && shadowStyles,
            ]}
            buttonStyle={[styles.liveFeedPostButtonLinkButton, shadow && shadowStyles]}
            titleStyle={[
              styles.liveFeedPostButtonLinkTitle,
              {
                fontFamily: getFontFamily(fontFamily, 'SemiBold'),
                fontSize: liveFeedPostButtonLinkTitleFontSize,
              },
            ]}
            ViewComponent={LinearGradient}
            linearGradientProps={{
              // Gradient initialized with a single color
              colors: [mainColor, mainColor],
              start: { x: 0, y: 0.5 },
              end: { x: 1, y: 0.5 },
            }}
            type={'solid'}
            title={i18n.t('liveFeed.openLink')}
            titleProps={{ numberOfLines: 1 }}
            onPress={() => Linking.openURL(link)}
            onPressIn={() => setShadow(false)} //TODO Fix de React 0.76.6
            onPressOut={() => setShadow(true)}
          />
        ) : null}
        <View
          style={[
            styles.liveFeedPostShareButton,
            shadowStyles,
            { backgroundColor: getSecondaryColor(mainColor) },
            IS_WEB ? { pointerEvents: 'none' } : undefined,
          ]}
        >
          <CustomIcon
            name="icon_share_with_offset"
            color={mainColor}
            size={mvs(22, 0.35)}
            onPress={() => {
              Share.share({
                message:
                  IS_ANDROID && shareUrl ? `${shareMessage} ${shareUrl}` : shareMessage,
                url: shareUrl,
              });
            }}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          />
        </View>
      </View>
    </>
  );
};

export default LiveFeedPostWrapper;

const styles = StyleSheet.create({
  liveFeedPost: {
    backgroundColor: '#fff',
    paddingTop: mvs(15),
    paddingBottom: mvs(35),
    marginBottom: mvs(45),
    marginTop: 10,
  },
  liveFeedPostHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: mvs(55),
    paddingRight: mvs(35),
  },
  liveFeedPostAuthor: {
    color: '#9C9C9C',
  },
  liveFeedPostDate: {
    color: '#9C9C9C',
    marginLeft: mvs(3),
    flex: 1,
  },
  liveFeedPostContents: {
    paddingRight: mvs(26),
    paddingLeft: isTablet ? mvs(55) : mvs(26),
    paddingTop: mvs(12),
  },
  liveFeedPostAvatarContainer: {
    backgroundColor: '#FFA502',
    borderWidth: mvs(3),
    borderColor: '#fff',
    position: 'absolute',
    top: mvs(-15),
  },
  liveFeedPostBottomActions: {
    position: 'absolute',
    bottom: mvs(23),
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingRight: mvs(32),
    paddingLeft: isTablet ? mvs(61) : mvs(32),
  },
  liveFeedPostButtonLinkContainer: {
    marginRight: mvs(7),
    flex: 1,
  },
  liveFeedPostButtonLinkButton: {
    height: mvs(46),
  },
  liveFeedPostButtonLinkTitle: {
    color: '#fff',
  },
  liveFeedPostShareButton: {
    width: mvs(46, 0.35),
    aspectRatio: 1,
    borderRadius: mvs(23),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
