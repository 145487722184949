import { Image, Linking, StyleSheet, TouchableOpacity, View } from 'react-native';
import { mvs } from 'react-native-size-matters';

import { LiveFeedPostWrapper } from './LiveFeedPostWrapper';
import { LiveFeedSurvey } from './LiveFeedSurvey';
import { useAppSelector } from '../../app/hooks';
import { IS_WEB } from '../../constants';
import {
  selectDivRadius,
  selectMainColor,
  selectTextAlign,
} from '../../features/appconfig/store/selectors';
import {
  computeSurveyResultBarStyles,
  parseSurveyResults,
} from '../../features/liveFeed/helpers/survey';
import { getSecondaryColor } from '../../helpers/getSecondaryColor';
import {
  thumbnailUrlFromYoutubeUrl,
  thumbnailUrlFromYoutubeVideoId,
} from '../../helpers/socialVideo';
import { useAdjustedFontSize } from '../../hooks/useAdjustedFontSize';
import { useShadowStyles } from '../../hooks/useShadowStyles';
import {
  LiveFeedPostArticleModel,
  LiveFeedPostModel,
  LiveFeedPostSurveyModel,
  LiveFeedPostSurveyResultsModel,
} from '../../models';
import { CustomIcon } from '../CustomIcon';
import CustomText from '../CustomText';

interface LiveFeedPostProps {
  liveFeedPost: LiveFeedPostModel;
}

export const LiveFeedPost = ({ liveFeedPost }: LiveFeedPostProps) => {
  const type = liveFeedPost.type;
  const message = liveFeedPost.message?.trim() ?? '';
  const picture = liveFeedPost.photo;
  const youtubeUrl = liveFeedPost.urlyoutube;
  const youtubeId = liveFeedPost.idyoutube;
  const link = liveFeedPost.lien;
  const mainColor = useAppSelector(selectMainColor);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  // Dynamic font sizes
  const liveFeedPostMessageFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const liveFeedPostTitleFontSize = useAdjustedFontSize(mvs(18, 0.35));

  let contents = (
    <>
      {picture ? (
        <Image
          style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
          source={{ uri: picture }}
        />
      ) : null}
      <CustomText
        style={[styles.liveFeedPostMessage, { fontSize: liveFeedPostMessageFontSize }]}
        textAlign={textAlign}
        weight="Regular"
      >
        {message}
      </CustomText>
    </>
  );

  if (youtubeUrl) {
    const thumbnailUrl =
      picture ??
      (youtubeId
        ? thumbnailUrlFromYoutubeVideoId(youtubeId)
        : thumbnailUrlFromYoutubeUrl(youtubeUrl));
    contents = (
      <>
        <TouchableOpacity
          style={IS_WEB ? { pointerEvents: 'none' } : undefined}
          onPress={() => Linking.openURL(youtubeUrl)}
        >
          {thumbnailUrl ? (
            <View style={styles.liveFeedPostPictureContainer}>
              <Image
                style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
                source={{ uri: thumbnailUrl }}
              />
              <View
                style={[
                  styles.liveFeedPostVideoButton,
                  { backgroundColor: getSecondaryColor(mainColor) },
                  IS_WEB ? { pointerEvents: 'none' } : undefined,
                ]}
              >
                <CustomIcon
                  name="icon_play_video_with_offset"
                  color={mainColor}
                  size={mvs(32)}
                />
              </View>
            </View>
          ) : (
            <CustomText
              style={[
                styles.liveFeedPostMessage,
                styles.liveFeedPostTextLink,
                { fontSize: liveFeedPostMessageFontSize },
              ]}
              weight="Regular"
              textAlign={textAlign}
            >
              {youtubeUrl}
            </CustomText>
          )}
        </TouchableOpacity>
        <CustomText
          style={[styles.liveFeedPostMessage, { fontSize: liveFeedPostMessageFontSize }]}
          textAlign={textAlign}
          weight="Regular"
        >
          {message}
        </CustomText>
      </>
    );
  } else if (['agenda', 'galeries', 'news', 'podcasts', 'videos'].includes(type)) {
    const article = liveFeedPost as LiveFeedPostArticleModel;
    const title = article.titre?.trim() ?? '';
    const description = article.description?.trim() ?? '';
    contents = (
      <>
        {picture ? (
          <View style={styles.liveFeedPostPictureContainer}>
            <Image
              style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
              source={{ uri: picture }}
            />
            {type === 'videos' && link ? (
              <View
                style={[
                  styles.liveFeedPostVideoButton,
                  shadowStyles,
                  { backgroundColor: getSecondaryColor(mainColor) },
                ]}
              >
                <CustomIcon
                  name="icon_play_video_with_offset"
                  color={mainColor}
                  size={mvs(23)}
                  onPress={() => Linking.openURL(link)}
                  hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        <>
          <CustomText
            style={[
              styles.liveFeedPostMessage,
              { fontSize: liveFeedPostMessageFontSize },
            ]}
            textAlign={textAlign}
            weight="Regular"
          >
            {message}
          </CustomText>
          {title ? (
            <CustomText
              style={[styles.liveFeedPostTitle, { fontSize: liveFeedPostTitleFontSize }]}
              weight="SemiBold"
              textAlign={textAlign}
            >
              {title}
            </CustomText>
          ) : null}
          {description ? (
            <CustomText
              style={[
                styles.liveFeedPostMessage,
                { fontSize: liveFeedPostMessageFontSize },
              ]}
              textAlign={textAlign}
              weight="Regular"
            >
              {description}
            </CustomText>
          ) : null}
        </>
      </>
    );
  } else if ((liveFeedPost as LiveFeedPostSurveyModel).question && type !== 'resultat') {
    return <LiveFeedSurvey liveFeedPost={liveFeedPost} />;
  } else if (type === 'resultat') {
    const survey = liveFeedPost as LiveFeedPostSurveyResultsModel;
    const question = survey.question;
    const {
      resultat1: result1,
      resultat2: result2,
      resultat3: result3,
      resultat4: result4,
      resultat5: result5,
    } = survey;
    const results = [result1, result2, result3, result4, result5];
    const resultsPercents = parseSurveyResults(survey);
    const maxPercent = Math.max(...resultsPercents);
    contents = (
      <>
        {picture ? (
          <Image
            style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
            source={{ uri: picture }}
          />
        ) : null}
        <>
          <CustomText
            style={[
              styles.liveFeedPostMessage,
              { fontSize: liveFeedPostMessageFontSize },
            ]}
            textAlign={textAlign}
            weight="Regular"
          >
            {message}
          </CustomText>
          <CustomText
            style={[styles.liveFeedPostTitle, { fontSize: liveFeedPostTitleFontSize }]}
            weight="SemiBold"
            textAlign={textAlign}
          >
            {question}
          </CustomText>
          <>
            {results.map((result, index) =>
              result ? (
                <View
                  key={`${result}-${index}`}
                  style={[
                    styles.liveFeedPostSurveyResultContainer,
                    { borderRadius: mvs(divRadius) },
                  ]}
                >
                  <View
                    style={[
                      styles.liveFeedPostSurveyResultBar,
                      computeSurveyResultBarStyles(resultsPercents[index]),
                      { borderRadius: mvs(divRadius) },
                    ]}
                  />
                  <CustomText
                    style={[styles.liveFeedPostSurveyResultText]}
                    weight={
                      resultsPercents[index] === maxPercent ? 'SemiBold' : 'Regular'
                    }
                  >
                    {result}
                  </CustomText>
                </View>
              ) : null,
            )}
          </>
        </>
      </>
    );
  }

  return (
    <LiveFeedPostWrapper liveFeedPost={liveFeedPost}>{contents}</LiveFeedPostWrapper>
  );
};

export default LiveFeedPost;

const styles = StyleSheet.create({
  liveFeedPostMessage: {
    color: '#9C9C9C',
  },
  liveFeedPostTitle: {
    color: '#474747',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostPictureContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  liveFeedPostPicture: {
    backgroundColor: '#EEE',
    width: '100%',
    aspectRatio: 16 / 9,
    resizeMode: 'cover',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostVideoButton: {
    width: mvs(51),
    aspectRatio: 1,
    borderRadius: mvs(26),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  liveFeedPostTextLink: {
    textDecorationLine: 'underline',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostSurveyResultContainer: {
    backgroundColor: '#2ED5731A',
    paddingVertical: mvs(12),
    paddingHorizontal: mvs(10),
    marginBottom: mvs(5, 0.35),
    justifyContent: 'center',
  },
  liveFeedPostSurveyResultBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#2ED573',
  },
  liveFeedPostSurveyResultText: {
    fontSize: mvs(16, 0.35),
    color: '#474747',
  },
});
