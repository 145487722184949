// components/LiveFeedSurvey.tsx

import { faCircle, faDotCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Button, ListItem } from '@rneui/base';
import { useCallback, useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { LiveFeedPostWrapper } from './LiveFeedPostWrapper';
import { sendVoteLiveFeedSurvey } from '../../api/radioWebsiteApi';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IS_WEB } from '../../constants';
import {
  selectAppConfig,
  selectDivRadius,
  selectFontFamily,
  selectMainColor,
  selectTextAlign,
} from '../../features/appconfig/store/selectors';
import { showAlert } from '../../features/modal/store/slice';
import { getFontFamily } from '../../helpers/fontMapper';
import { getSecondaryColor } from '../../helpers/getSecondaryColor';
import { useAdjustedFontSize } from '../../hooks/useAdjustedFontSize';
import i18n from '../../localization/i18n';
import {
  LiveFeedPostModel,
  LiveFeedPostSurveyAnswer,
  LiveFeedPostSurveyModel,
} from '../../models';
import CustomText from '../CustomText';

interface LiveFeedSurveyProps {
  liveFeedPost: LiveFeedPostModel;
}

export const LiveFeedSurvey = ({ liveFeedPost }: LiveFeedSurveyProps) => {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const [isSubmittingVote, setIsSubmittingVote] = useState(false);
  const [isVoteSubmitted, setIsVoteSubmitted] = useState(false);
  const [choice, setChoice] = useState<LiveFeedPostSurveyAnswer | null>(null);
  const [isSurveyButtonDisabled, setIsSurveyButtonDisabled] = useState(true);
  const mainColor = useAppSelector(selectMainColor);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);

  // Dynamic font sizes
  const liveFeedPostMessageFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const liveFeedPostTitleFontSize = useAdjustedFontSize(mvs(18, 0.35));
  const liveFeedPostButtonLinkTitleFontSize = useAdjustedFontSize(mvs(20, 0.35));

  const id = liveFeedPost.idlivefeeds;
  const message = liveFeedPost.message?.trim() ?? '';
  const picture = liveFeedPost.photo;
  const survey = liveFeedPost as LiveFeedPostSurveyModel;
  const isSurveyActive = survey.sondageactif === '1';
  const question = survey.question;
  const {
    reponse1: answer1,
    reponse2: answer2,
    reponse3: answer3,
    reponse4: answer4,
    reponse5: answer5,
  } = survey;
  const answers = [answer1, answer2, answer3, answer4, answer5];
  const answersChoices: LiveFeedPostSurveyAnswer[] = ['1', '2', '3', '4', '5'];

  useEffect(() => {
    setIsSurveyButtonDisabled(choice === null);
  }, [choice]);

  const onVoteSurvey = useCallback(
    async (
      liveFeedPostId: LiveFeedPostSurveyModel['idlivefeeds'],
      answer: LiveFeedPostSurveyAnswer,
    ) => {
      if (!appConfig.urlSiteRadio) {
        return;
      }
      try {
        setIsSubmittingVote(true);
        await sendVoteLiveFeedSurvey(appConfig.urlSiteRadio, liveFeedPostId, answer);
        setIsVoteSubmitted(true);
      } catch (err) {
        dispatch(
          showAlert({
            key: 'send-survey-error',
            type: 'warning',
            text: i18n.t('alert.sendSurveyError'),
            closable: false,
            autoCloseAfterMs: 2000,
          }),
        );
      } finally {
        setIsSubmittingVote(false);
      }
    },
    [appConfig, dispatch],
  );

  return (
    <LiveFeedPostWrapper
      liveFeedPost={liveFeedPost}
      bottomActionButton={
        isSurveyActive && !isVoteSubmitted ? (
          <Button
            containerStyle={[
              styles.liveFeedPostButtonLinkContainer,
              { borderRadius: mvs(divRadius) },
            ]}
            buttonStyle={styles.liveFeedPostButtonLinkButton}
            titleStyle={[
              styles.liveFeedPostButtonLinkTitle,
              {
                fontFamily: getFontFamily(fontFamily, 'SemiBold'),
                fontSize: liveFeedPostButtonLinkTitleFontSize,
              },
            ]}
            ViewComponent={LinearGradient}
            linearGradientProps={{
              colors: isSurveyButtonDisabled
                ? [getSecondaryColor(mainColor), getSecondaryColor(mainColor)]
                : [mainColor, mainColor],
              start: { x: 0, y: 0.5 },
              end: { x: 1, y: 0.5 },
            }}
            type={'solid'}
            title={i18n.t(
              isSubmittingVote ? 'liveFeed.submittingSurvey' : 'liveFeed.submitSurvey',
            )}
            titleProps={{ numberOfLines: 1 }}
            disabled={isSurveyButtonDisabled || IS_WEB}
            disabledTitleStyle={styles.liveFeedPostButtonLinkTitleDisabled}
            onPress={() => choice && onVoteSurvey(id, choice)}
          />
        ) : null
      }
    >
      {picture ? (
        <Image
          style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
          source={{ uri: picture }}
        />
      ) : null}
      <>
        <CustomText
          style={[styles.liveFeedPostMessage, { fontSize: liveFeedPostMessageFontSize }]}
          weight="Regular"
          textAlign={textAlign}
        >
          {message}
        </CustomText>
        <CustomText
          style={[styles.liveFeedPostTitle, { fontSize: liveFeedPostTitleFontSize }]}
          weight="SemiBold"
          textAlign={textAlign}
        >
          {question}
        </CustomText>
        {isSurveyActive ? (
          !isVoteSubmitted ? (
            <>
              {answers.map((answer, index) =>
                answer ? (
                  <ListItem.CheckBox
                    key={`${answer}-${index}`}
                    containerStyle={[
                      styles.liveFeedPostSurveyAnswerContainer,
                      { borderRadius: mvs(divRadius) },
                      choice === answersChoices[index]
                        ? [
                            {
                              borderColor: mainColor,
                              backgroundColor: getSecondaryColor(mainColor),
                            },
                          ]
                        : null,
                    ]}
                    textStyle={[
                      styles.liveFeedPostSurveyAnswerText,
                      choice === answersChoices[index]
                        ? styles.liveFeedPostSurveyAnswerTextSelected
                        : null,
                      { fontFamily: getFontFamily(fontFamily, 'Regular') },
                    ]}
                    title={answer}
                    checked={choice === answersChoices[index]}
                    checkedIcon={
                      <FontAwesomeIcon
                        size={25}
                        secondaryColor={'#FF0000'}
                        color={mainColor}
                        icon={faDotCircle}
                      />
                    }
                    uncheckedIcon={
                      <FontAwesomeIcon
                        size={25}
                        secondaryColor={'#FF0000'}
                        color={'#D9D9D9'}
                        icon={faCircle}
                      />
                    }
                    checkedColor={mainColor}
                    uncheckedColor={'#D9D9D9'}
                    onPress={() => setChoice(answersChoices[index])}
                  />
                ) : null,
              )}
            </>
          ) : (
            <View
              style={[
                styles.liveFeedPostSurveySubmittedMessage,
                { borderRadius: mvs(divRadius) },
              ]}
            >
              <CustomText
                style={styles.liveFeedPostSurveySubmittedMessageText}
                weight="SemiBold"
                textAlign={textAlign}
              >
                {i18n.t('liveFeed.submittedSurvey')}
              </CustomText>
            </View>
          )
        ) : (
          <View
            style={[
              styles.liveFeedPostSurveyClosedMessage,
              {
                backgroundColor: getSecondaryColor(mainColor),
                borderRadius: mvs(divRadius),
              },
            ]}
          >
            <CustomText
              style={[styles.liveFeedPostSurveyClosedMessageText, { color: mainColor }]}
              weight="SemiBold"
              textAlign={textAlign}
            >
              {i18n.t('liveFeed.closedSurvey')}
            </CustomText>
          </View>
        )}
      </>
    </LiveFeedPostWrapper>
  );
};

export default LiveFeedSurvey;

const styles = StyleSheet.create({
  liveFeedPostPicture: {
    backgroundColor: '#EEE',
    width: '100%',
    aspectRatio: 16 / 9,
    resizeMode: 'cover',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostMessage: {
    color: '#9C9C9C',
  },
  liveFeedPostTitle: {
    color: '#474747',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostSurveyAnswerContainer: {
    backgroundColor: '#fff',
    paddingVertical: mvs(12),
    paddingHorizontal: mvs(10),
    marginBottom: mvs(5, 0.35),
    borderWidth: 1,
    borderColor: '#EEEEEE',
  },
  liveFeedPostSurveyAnswerText: {
    fontSize: mvs(16, 0.35),
    fontWeight: 'normal',
    color: '#9C9C9C',
  },
  liveFeedPostSurveyAnswerTextSelected: {
    color: '#474747',
  },
  liveFeedPostSurveySubmittedMessage: {
    backgroundColor: '#2ED57312',
    minHeight: mvs(45, 0.35),
    alignItems: 'center',
    justifyContent: 'center',
  },
  liveFeedPostSurveySubmittedMessageText: {
    fontSize: mvs(16, 0.35),
    color: '#2ED573',
    paddingHorizontal: mvs(20),
    paddingVertical: mvs(10),
    textAlign: 'center',
  },
  liveFeedPostSurveyClosedMessage: {
    minHeight: mvs(45, 0.35),
    alignItems: 'center',
    justifyContent: 'center',
  },
  liveFeedPostSurveyClosedMessageText: {
    fontSize: mvs(16, 0.35),
    paddingHorizontal: mvs(20),
    paddingVertical: mvs(10),
    textAlign: 'center',
  },
  liveFeedPostButtonLinkContainer: {
    marginRight: mvs(7),
    flex: 1,
  },
  liveFeedPostButtonLinkButton: {
    height: mvs(46),
  },
  liveFeedPostButtonLinkTitle: {
    color: '#fff',
  },
  liveFeedPostButtonLinkTitleDisabled: {
    color: '#ffffff80',
  },
});
