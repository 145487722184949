import { ReactNode, useState } from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';
import styled from 'styled-components/native';

import { COLORS , IS_IOS } from '../constants';
import { useButtonColors } from '../hooks/useButtonColors';
import { useShadowStyles } from '../hooks/useShadowStyles';


interface Props extends ViewProps {
  children: ReactNode;
}

export const RecordButtonIconContainer = ({ style, children }: Props) => {
  const { gradientBackgroundColours, buttonStyle, buttonColors } = useButtonColors();
  const shadowStyles = useShadowStyles();
  const [shadow, setShadow] = useState(true);

  const buttonDynamicStyles =
    buttonStyle === 'primary'
      ? {
          borderColor: COLORS.WHITE,
          borderWidth: 5,
        }
      : {
          borderColor: buttonColors.secondaryColor,
          borderWidth: 0,
        };

  return (
    <Button
      style={[style, buttonDynamicStyles, !IS_IOS && shadow && shadowStyles]}
      onPressIn={() => setShadow(false)} //TODO Fix de React 0.76.6
      onPressOut={() => setShadow(true)}
    >
      <LinearGradient
        // gradient initialized with a single color
        // prepared for future addition of a secondary color.
        colors={gradientBackgroundColours}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={[styles.background]}
      >
        {children}
      </LinearGradient>
    </Button>
  );
};

const Button = styled.View`
  width: ${mvs(58)};
  aspect-ratio: 1;
  border-radius: ${mvs(44)}px;
  align-self: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: mvs(58),
    alignItems: 'center',
    justifyContent: 'center',
  },
});
